import React from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import SwapIcon from '@mui/icons-material/SwapHoriz';
import PoolIcon from '@mui/icons-material/Waves';
import InfoIcon from '@mui/icons-material/GridView';
import BridgeIcon from '@mui/icons-material/AllInclusive';
import LauncpadIcon from '@mui/icons-material/RocketLaunch';


export default function Layout({account, setAccount, page, children}) {
    const isMobile = window.screen.width < 600
    const [value, setValue2] = React.useState(0);
  return (
    <>
        {/*<Sidebar page={page} />*/}
        <Header account={account} setAccount={setAccount} />
        <div className="page-content">
          {children}
        </div>
        {isMobile &&
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor : '#1e1f24', fontSize : '10px'}} elevation={3}>
                <BottomNavigation
                    sx={{backgroundColor : '#1e1f24', color: 'red', "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
                            color: "#fff", fontFamily : 'Fira Code',
                        },
                        "& .Mui-selected > svg, & .Mui-selected ": {
                            color: "#2196f3", fontFamily : 'Fira Code',
                        },  }}
                    showLabels
                    value={4}
                    onChange={(event, newValue) => {
                        setValue2(newValue);
                    }}
                >
                    <BottomNavigationAction label="Swap" href={'//app.icecreamswap.com/#/swap'} icon={<SwapIcon />}/>
                    <BottomNavigationAction label="Pool" href={'//app.icecreamswap.com/#/pool'} icon={<PoolIcon />}/>
                    <BottomNavigationAction label="Info" href={'//info.icecreamswap.com'} icon={<InfoIcon />}/>
                    <BottomNavigationAction label="Bridge" href={'//bridge.icecreamswap.com'} icon={<BridgeIcon />}/>
                    <BottomNavigationAction label="Launchpad" icon={<LauncpadIcon />}/>
                </BottomNavigation>
            </Paper>
        }
    </>
  )
}
