import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Progress from "./Progress";
import { convertTime } from '../utils/calculate';
import { useNavigate } from "react-router-dom";


export default function LaunchpadCard({ address, img, state, tokenName, isFair, tokenRate, tokenSymbol, priceFirst, priceSecond, priceThird, liquidity, lockTime, startTime, endTime }) {

  const [timeToStart, setTimeToStart] = useState(null);
  const [timeToEnd, setTimeToEnd] = useState(null);
  const progress = (Number(priceThird) / Number(priceFirst) * 100).toFixed(2);

  const navigate = useNavigate();

  let interval;
  useEffect(() => {
    clearInterval(interval)
    interval = setInterval(() => {
      const _now = moment().format("X");
      const _timeToStart = moment(startTime).format("X") - _now;
      const _timeToEnd = moment(endTime).format("X") - _now;
      setTimeToStart(_timeToStart);
      setTimeToEnd(_timeToEnd);
    }, 1000)
  }, [])

  return (
    <div className='launchpad-card'>
      <div className='avatar-sale'>
        <img className='avatar' alt='' src={img}></img>
        {state === 1 && <div className='sale-upcomming'><span></span>Upcomming</div>}
        {state === 2 && <div className='sale-live'><span></span>Sale Live</div>}
        {state === 3 && <div className='sale-filled'><span></span>Filled</div>}
        {state === 4 && <div className='sale-ended'><span></span>Sale Ended</div>}
        {state === 5 && <div className='sale-canceled'><span></span>Canceled</div>}
      </div>
      <div className='title'>{tokenName}</div>

      {!isFair &&
        <>
          <div className='coin-fair'>1 BNB = {tokenRate} {tokenSymbol}</div>
          <div className='soft-hard'>Soft/Hard</div>
          <div className='price'>{priceSecond} Brise - {priceFirst} Brise</div>
          <div className='progress-number'>Progress ({progress}%)</div>
          <Progress progress={progress} />
          <div className='price-range'>
            <div>{priceThird} BNB</div>
            <div>{priceFirst} BNB</div>
          </div>
        </>
      }

      {isFair &&
        <>
          <div className='coin-fair'>Fair Launch</div>
          <div className='soft-hard'>Soft</div>
          <div className='price'>{priceFirst} ETH</div>
          <div className='progress-number'>Progress ({progress}%)</div>
          <Progress progress={progress} />
          <div className='price-range'>
            <div className='price-item'>{priceThird} BNB</div>
            <div className='price-item'>{priceFirst} BNB</div>
          </div>
        </>
      }

      <div className='liqudity'>
        <div className='title'>Liquidity %:</div>
        <div className='subtitle'>{liquidity}%</div>
      </div>

      <div className='lock'>
        <div className='title'>Lockup Time:</div>
        <div className='subtitle'>{lockTime} mins</div>
      </div>
      <div className='divider'></div>
      <div className='time-view'>
        <div>
          {state === 1 && <><div className='time-state'>Sale Starts In:</div><div className='time'>{convertTime(timeToStart)}</div></>}
          {state === 2 && <><div className='time-state'>Sale Ends In:</div><div className='time'>{convertTime(timeToEnd)}</div></>}
          {state === 3 && <><div className='time-state'>Presale:</div><div className='time'>Filled</div></>}
          {state === 4 && <><div className='time-state'>Presale:</div><div className='time'>Ended</div></>}
          {state === 5 && <><div className='time-state'>Presale:</div><div className='time'>Canceled</div></>}
        </div>
        <div className='default-btn' onClick={() => navigate(`pool/${address}`)}>View Pool</div>
      </div>

    </div>
  )
}
