import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useMutation } from "@apollo/client";

import { CREATE_STANDARD_TOKEN } from "../../../graphql/Launchpad/Mutation";
import { getStandardTokenFactory, getAntiBotStandardTokenFactory } from "../../../utils/contractHelpers";
import {StandardTokenFactory_Addr, AntiBotStandardTokenFactory_Addr, AntiBot_Addr} from '../../../utils/addressHelpers';
import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';

export default function StandardToken({ account, networkID }) {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [decimals, setDecimals] = useState("18");
  const [totalSupply, setTotalSupply] = useState("");
  const [isAntiBot, setIsAntiBot] = useState(false);

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  const [createStandardToken, { error }] = useMutation(CREATE_STANDARD_TOKEN);

  const onCreateToken = async () => {
    if (!account) return;

    try {
      let temp = Web3.utils.toWei(totalSupply);
      let tokenAddress;
      setPending(true);

      if (!isAntiBot) {
        const contract = await getStandardTokenFactory(StandardTokenFactory_Addr, true);
        const receipt = await contract.methods.create(name, symbol, decimals, temp).send({ from: account, value: '0x108B2A2C28029094000000' /*20M*/});
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      else {
        const contract = await getAntiBotStandardTokenFactory(AntiBotStandardTokenFactory_Addr, true);
        const receipt = await contract.methods.create(name, symbol, decimals, temp, AntiBot_Addr).send({ from: account, value: '0x108B2A2C28029094000000' /*20M*/});
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      try {
        createStandardToken({ variables: { name: name, symbol: symbol, decimals: decimals, totalSupply: totalSupply, isAntiBot: isAntiBot, tokenAddress: tokenAddress, creatorAddress: account, chain: networkID } });
      } catch (error) {
        console.log(error);
      }
      setPending(false);
    }
    catch (error) {
      setPending(false);
      console.log(error);
    }
  }

  useEffect(() => {
    let temp = [];
    temp[0] = (name.length === 0) ? 1 : 0;
    temp[1] = (symbol.length === 0) ? 1 : 0;
    temp[2] = (decimals.length === 0) ? 1 : (!isNumber(decimals) ? 2 : 0);
    temp[3] = (totalSupply.length === 0) ? 1 : (!isNumber(totalSupply) ? 2 : 0);
    setCheckFlags(temp);
    setCreateFlag(!temp[0] * !temp[1] * !temp[2] * !temp[3]);
  }, [name, symbol, decimals, totalSupply]);

  return (
    <>
      <TextInput
        title="Name"
        isRequired={true}
        value={name}
        setValue={setName}
        placeholder="Ex: Ethereum"
        warning={checkFlags[0]}
      />
      <TextInput
        title="Symbol"
        isRequired={true}
        value={symbol}
        setValue={setSymbol}
        placeholder="Ex: ETH"
        warning={checkFlags[1]}
      />
      {/*<TextInput
        title="Decimals"
        isRequired={true}
        value={decimals}
        setValue={setDecimals}
        placeholder="Ex: 18"
        warning={checkFlags[2]}
      />*/}
      <TextInput
        title="Total Supply"
        isRequired={true}
        value={totalSupply}
        setValue={setTotalSupply}
        placeholder="Ex: 100000000000"
        warning={checkFlags[3]}
      />
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isAntiBot} onChange={(e) => setIsAntiBot(e.target.checked)} />
          <label htmlFor="currency-bnb">Implement Pink Anti-Bot System?</label>
        </div>
      </div>
      <div className="btn-group">
        <button className="default-btn" disabled={!createFlag || pending} onClick={onCreateToken}>Create Token</button>
      </div>
    </>
  )
}
