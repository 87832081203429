import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { Link } from 'react-router-dom';

import { getERC20Contract, getPoolFactoryContract } from "../../../utils/contractHelpers"
import { PoolFactory_Addr } from '../../../utils/addressHelpers';
import TextInput from '../../../components/TextInput';
import { notify } from '../../../utils/notifyFunctions';

export default function Step1({
    account, setStep,
    tokenAddress, setTokenAddress,
    tokenName, setTokenName,
    tokenSymbol, setTokenSymbol,
    tokenDecimals, setTokenDecimals,
    tokenTotalSupply, setTokenTotalSupply,
    currency, setCurrency,
    feeOptions, setFeeOptions
}) {

    // const [createFlag, setCreateFlag] = useState(false);
    const [approveFlag, setApproveFlag] = useState(0);
    const [pending, setPending] = useState(false);
    const [checkFlag, setCheckFlag] = useState(3);



    // useEffect(() => {
    //     let temp = [];
    //     temp[0] = Web3.utils.isAddress(tokenAddress) ? 0 : 3;
    //     setCheckFlags(temp);
    //     setCreateFlag(!temp[0]);
    // }, tokenAddress)

    useEffect(() => {
        fetchData();
    }, [account, tokenAddress])

    async function fetchData() {
        if (!Web3.utils.isAddress(tokenAddress) || !account) {
            setCheckFlag(3);
            setApproveFlag(0);
            return;
        }
        let PoolFactory;
        PoolFactory = await getPoolFactoryContract(PoolFactory_Addr);
        console.log(PoolFactory);
        const isExisting = await PoolFactory.methods.isExisting(tokenAddress).call();
        if (isExisting) {
            setCheckFlag(6);
            setApproveFlag(0);
            return;
        }
        const contract = await getERC20Contract(tokenAddress);
        if (!contract) return;
        try {
            const name = await contract.methods.name().call();
            const symbol = await contract.methods.symbol().call();
            const decimals = await contract.methods.decimals().call();
            const allowance = await contract.methods.allowance(account, PoolFactory._address).call();
            const totalSupply = await contract.methods.totalSupply().call();
            setTokenName(name);
            setTokenSymbol(symbol);
            setTokenDecimals(decimals);
            setTokenTotalSupply(totalSupply);
            if (allowance > 0) setApproveFlag(1);
            else setApproveFlag(2);
            setCheckFlag(0);
        } catch (error) {
            console.log(error);
            setCheckFlag(3);
            setApproveFlag(0);
        }
    }

    const approveToken = async () => {
        if (!tokenAddress.length || !account) return;
        const contract = await getERC20Contract(tokenAddress, true);
        if (!contract) return;
        try {
            setPending(true);
            let poolfactoryaddress;
            poolfactoryaddress = PoolFactory_Addr;
            await contract.methods.approve(poolfactoryaddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account });
            setPending(false);
            setApproveFlag(1);
        } catch (error) {
            console.log(error);
            notify(2, "Something went wrong")
            setPending(false);
            setApproveFlag(2);
        }
    }

    return (
        <div className="step-1">
            <div className="top">
                <div className="warning-text">(*) is required field.</div>
                <Link to="/create_token"><button className="default-btn">Create Token</button></Link>
            </div>
            <TextInput
                title="Token addresss"
                isRequired={true}
                value={tokenAddress}
                setValue={setTokenAddress}
                placeholder="Ex: 0x..."
                warning={checkFlag}
                tips="Pool creation fee: 100M Brise"
            />
            {
                !checkFlag &&
                <>
                    <div className="div-line" />
                    <div className="token-info-item">
                        <div>Name</div>
                        <div>{tokenName}</div>
                    </div>
                    <div className="token-info-item">
                        <div>Symbol</div>
                        <div>{tokenSymbol}</div>
                    </div>
                    <div className="token-info-item">
                        <div>Decimals</div>
                        <div>{tokenDecimals}</div>
                    </div>
                </>
            }
            {/*<div className="div-line" />
            <div className="radio-input-item">
                <div className="text-white">Currency</div>
                <div>
                    <input className="radio-input" type="radio" checked={currency === "BNB"} onChange={() => setCurrency("BNB")} />
                    <label>BNB</label>
                </div>
                <div>
                    <input className="radio-input" type="radio" checked={currency === "BUSD"} onChange={() => setCurrency("BUSD")} />
                    <label>BUSD</label>
                </div>
                <div>
                    <input className="radio-input" type="radio" checked={currency === "USDC"} onChange={() => setCurrency("USDC")} />
                    <label>USDC</label>
                </div>
                <div>
                    <input className="radio-input" type="radio" checked={currency === "USDT"} onChange={() => setCurrency("USDT")} />
                    <label>USDT</label>
                </div>
                <div className="tips-text">Users will pay with {currency} for your token</div>
            </div>*/}
            <div className="div-line" />
            <div className="radio-input-item">
                <div className="text-white">Fee Options</div>
                {/*<div>
                    <input className="radio-input" type="radio" checked={feeOptions === 0} onChange={() => setFeeOptions(0)} />
                    <label>5% BNB raised only</label>
                </div>*/}
                <div>
                    <input className="radio-input" type="radio" checked={feeOptions === 1} onChange={() => setFeeOptions(1)} />
                    <label>2% BNB raised + 2% token raised</label>
                </div>
            </div>
            <div className="btn-group">
                {
                    approveFlag !== 2 &&
                    <button className="default-btn" disabled={!approveFlag} onClick={() => setStep(prev => prev + 1)}>Next</button>
                }
                {
                    approveFlag === 2 &&
                    <button className="default-btn" disabled={checkFlag || pending} onClick={() => approveToken()}>Approve</button>
                }
            </div>
        </div>
    )
}
