import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useMutation } from "@apollo/client";

import { CREATE_BABY_TOKEN } from "../../../graphql/Launchpad/Mutation";
import { getBabyTokenFactory, getAntibotBabyTokenFactory } from '../../../utils/contractHelpers';
import {
  BabyTokenFactory_Addr,
  AntiBot_Addr,
  AntiBotBabyTokenFactory_Addr,
  IcecreamRouter_Addr
} from '../../../utils/addressHelpers'
import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';

export default function BabyToken({ account, networkID }) {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [router, setRouter] = useState("IcecreamSwap");
  const [rewardToken, setRewardToken] = useState("");
  const [minimumTokenBalance, setMinimumTokenBalance] = useState("");
  const [tokenRewardFee, setTokenRewardFee] = useState("");
  const [autoAddLiquidity, setAutoAddLiquidity] = useState("");
  const [marketingFee, setMarketingFee] = useState("");
  const [marketingWallet, setMarketingWallet] = useState("");
  const [isAntiBot, setIsAntiBot] = useState(false);

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  const [createBabyToken, { error }] = useMutation(CREATE_BABY_TOKEN);

  const onCreateToken = async () => {
    if (!account) return;

    try {
      let temp = Web3.utils.toWei(totalSupply);
      let tokenAddress;
      setPending(true);

      if (!isAntiBot) {
        const contract = await getBabyTokenFactory(BabyTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          // todo: check what dividend tracker shuld be
          [rewardToken, IcecreamRouter_Addr, marketingWallet, '0xC2a5ea1d4406EC5fdd5eDFE0E13F59124C7e9803'],
          [tokenRewardFee, autoAddLiquidity, marketingFee],
          minimumTokenBalance
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      else {
        const contract = await getAntibotBabyTokenFactory(AntiBotBabyTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          // todo: check what dividend tracker shuld be
          [rewardToken, IcecreamRouter_Addr, marketingWallet, '0xC2a5ea1d4406EC5fdd5eDFE0E13F59124C7e9803', AntiBot_Addr],
          [tokenRewardFee, autoAddLiquidity, marketingFee],
          minimumTokenBalance,
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      try {
        createBabyToken({ variables: { name: name, symbol: symbol, totalSupply: totalSupply, rewardToken: rewardToken, minimumTokenBalance: minimumTokenBalance, tokenRewardFee: tokenRewardFee, autoAddLiquidity: autoAddLiquidity, marketingFee: marketingFee, marketingWallet: marketingWallet, isAntiBot: isAntiBot, tokenAddress: tokenAddress, chain: networkID } });
      } catch (error) {
        console.log(error);
      }
      setPending(false);
    }
    catch (error) {
      setPending(false);
      console.log(error);
    }
  }

  useEffect(() => {
    let temp = [];
    temp[0] = (name.length === 0) ? 1 : 0;
    temp[1] = (symbol.length === 0) ? 1 : 0;
    temp[2] = (totalSupply.length === 0) ? 1 : (!isNumber(totalSupply) ? 2 : 0);
    temp[3] = Web3.utils.isAddress(rewardToken) ? 0 : 3;
    temp[4] = (minimumTokenBalance.length === 0) ? 1 : (!isNumber(minimumTokenBalance) ? 2 : 0);
    temp[5] = (tokenRewardFee.length === 0) ? 1 : (!isNumber(tokenRewardFee) ? 2 : (Number(tokenRewardFee) > 100 || Number(tokenRewardFee) < 0 ? 4 : 0));
    temp[6] = (autoAddLiquidity.length === 0) ? 1 : (!isNumber(autoAddLiquidity) ? 2 : (Number(autoAddLiquidity) > 100 || Number(autoAddLiquidity) < 0 ? 4 : 0));
    temp[7] = (marketingFee.length === 0) ? 1 : (!isNumber(marketingFee) ? 2 : (Number(marketingFee) > 100 || Number(marketingFee) < 0 ? 4 : 0));
    temp[8] = Web3.utils.isAddress(marketingWallet) ? 0 : 3;
    setCheckFlags(temp);
    setCreateFlag(!temp[0] * !temp[1] * !temp[2] * !temp[3] * !temp[4] * !temp[5] * !temp[6] * !temp[7] * !temp[8]);
  }, [name, symbol, totalSupply, rewardToken, minimumTokenBalance, tokenRewardFee, autoAddLiquidity, marketingFee, marketingWallet])

  return (
    <>
      <TextInput
        title="Name"
        isRequired={true}
        value={name}
        setValue={setName}
        placeholder="Ex: Ethereum"
        warning={checkFlags[0]}
      />
      <TextInput
        title="Symbol"
        isRequired={true}
        value={symbol}
        setValue={setSymbol}
        placeholder="Ex: ETH"
        warning={checkFlags[1]}
      />
      <TextInput
        title="Total Supply"
        isRequired={true}
        value={totalSupply}
        setValue={setTotalSupply}
        placeholder="Ex: 100000000000"
        warning={checkFlags[2]}
      />
      <div className="text-input-item">
        <label htmlFor="router" className="text-white"><div>Router</div></label>
        <select id="router" className="text-input" value={router} onChange={(e) => setRouter(e.target.value)}>
          <option value="IcecreamSwap">IcecreamSwap</option>
        </select>
      </div>
      <div className="text-input-group">
        <TextInput
          title="Reward Token"
          isRequired={true}
          value={rewardToken}
          setValue={setRewardToken}
          placeholder="Ex: 0x..."
          warning={checkFlags[3]}
        />
        <TextInput
          title="Minimum token balance for dividends"
          isRequired={true}
          value={minimumTokenBalance}
          setValue={setMinimumTokenBalance}
          placeholder="Ex: 100000000000"
          warning={checkFlags[4]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Token reward fee (%)"
          isRequired={true}
          value={tokenRewardFee}
          setValue={setTokenRewardFee}
          placeholder="Ex: 0~100"
          warning={checkFlags[5]}
        />
        <TextInput
          title="Auto add liquidity (%)"
          isRequired={true}
          value={autoAddLiquidity}
          setValue={setAutoAddLiquidity}
          placeholder="Ex: 0~100"
          warning={checkFlags[6]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Marketing fee (%)"
          isRequired={true}
          value={marketingFee}
          setValue={setMarketingFee}
          placeholder="Ex: 0~100"
          warning={checkFlags[7]}
        />
        <TextInput
          title="Marketing wallet"
          isRequired={true}
          value={marketingWallet}
          setValue={setMarketingWallet}
          placeholder="Ex: 0x..."
          warning={checkFlags[8]}
        />
      </div>
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isAntiBot} onChange={(e) => setIsAntiBot(e.target.checked)} />
          <label htmlFor="currency-bnb">Implement Pink Anti-Bot System?</label>
        </div>
      </div>
      <div className="btn-group">
        <button className="default-btn" disabled={!createFlag || pending} onClick={onCreateToken}>Create Token</button>
      </div>
    </>
  )
}
