import React, { useState, useEffect } from 'react';
import {Link, useParams} from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import moment from 'moment';
import Web3 from 'web3';

import Progress from '../../../components/Progress';
import Donut from '../../../components/Donut';
import { GET_LAUNCHPAD_BY_ADDRESS, GET_WHITELIST } from "../../../graphql/Launchpad/Queries";
import { UPDATE_SOLD_AMOUNT, CANCEL_LAUNCHPAD, FINALIZE_LAUNCHPAD, CREATE_WHITELIST } from '../../../graphql/Launchpad/Mutation';
import { convertTime } from '../../../utils/calculate';
import { getBalance, getPoolContract, getERC20Contract } from '../../../utils/contractHelpers';
import { notify } from "../../../utils/notifyFunctions";

import website from "../../../assets/img/launchpad/website.svg";
import facebook from "../../../assets/img/launchpad/facebook.svg";
import twitter from "../../../assets/img/launchpad/twitter.svg";
import github from "../../../assets/img/launchpad/github.svg";
import telegram from "../../../assets/img/launchpad/telegram.svg";
import instagram from "../../../assets/img/launchpad/instagram.svg";
import discord from "../../../assets/img/launchpad/discord.svg";
import reddit from "../../../assets/img/launchpad/reddit.svg";
import youtube from "../../../assets/img/launchpad/youtube.svg";
import {Button, Col, Row, Tooltip} from "antd";
import {Box} from "@mui/material";
import {TabsUnstyled} from "@mui/base";
import {RiCoinLine} from "react-icons/ri";
import {IoRocketOutline} from "react-icons/io5";
import {MdKeyboardBackspace} from "react-icons/md";


export default function PoolDetails({ account, setPage, networkID }) {

  const [state, setState] = useState(null);
  const [poolData, setPoolData] = useState(null);
  const [timeToStart, setTimeToStart] = useState(null);
  const [timeToEnd, setTimeToEnd] = useState(null);
  const [buyAmount, setBuyAmount] = useState("");
  const [whitelistText, setWhitelistText] = useState("");
  const [contractData, setContractData] = useState(null);
  const [tokenData, setTokenData] = useState(null);

  const { address } = useParams();

  const { data, loading, error } = useQuery(GET_LAUNCHPAD_BY_ADDRESS, {
    variables: {
      launchpadAddress: address,
      chain: networkID
    }
  });

  const whitelistData = useQuery(GET_WHITELIST, {
    variables: {
      launchpadAddress: address,
      chain: networkID
    }
  })

  const [updateSoldAmount] = useMutation(UPDATE_SOLD_AMOUNT);
  const [cancelLaunchpad] = useMutation(CANCEL_LAUNCHPAD);
  const [finalizeLaunchpad] = useMutation(FINALIZE_LAUNCHPAD);
  const [createWhitelist] = useMutation(CREATE_WHITELIST);

  const getContractData = async () => {
    if (!account) return;
    try {
      const poolContract = await getPoolContract(address, account);
      console.log(poolContract)
      const _purchaseAmount = await poolContract.methods.contributionOf(account).call();
      const _finalizeTime = await poolContract.methods.finalizeTime().call();
      console.log(moment('1664555106').format('X'));
      setContractData({
        purchaseAmount: _purchaseAmount,
        finalizeTime: _finalizeTime
      })
      const tokenContract = await getERC20Contract(poolData?.tokenAddress, account);
      const tokenBalance = await tokenContract.methods.balanceOf(account).call();
      setTokenData({
        balance: tokenBalance
      })
    } catch (err) {
      console.log(err.message)
    }
  }

  // const getWhitelist = () => {
  //   if (!account) return;
  //   try {
  //     const _whitelist = whitelist?.data
  //     console.log(data)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const getMaxAmount = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    if (!poolData) {
      notify(1, "Pool data not loaded");
      return;
    }
    const balance = await getBalance(account);
    setBuyAmount(Math.min(Number(balance), poolData.maxBuy));
  }

  const buy = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    if (!buyAmount) {
      notify(1, "Amount not input");
      return;
    }
    if (buyAmount < poolData.minBuy || buyAmount > poolData.maxBuy) {
      notify(1, "Amount out of range");
      return;
    }
    try {
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.contribute().send({ from: account, value: buyAmount * 1e18 });
      updateSoldAmount({ variables: { launchpadAddress: address, soldAmount: buyAmount } })
      notify(0, `You bought ${buyAmount} ${poolData.currency}`);
      getContractData();
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const cancel = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    try {
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.cancel().send({ from: account });
      cancelLaunchpad({ variables: { launchpadAddress: address } })
      notify(0, `You have canceled the pool ${address}`);
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const finalize = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    try {
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.finalize().send({ from: account });
      finalizeLaunchpad({ variables: { launchpadAddress: address, chain: networkID } })
      notify(0, `You have finalized the pool ${address}`);
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const claim = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    try {
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.claim().send({ from: account });
      notify(0, `claim success`);
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const withdrawTeamVestings = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    try {
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.withdrawTeamVestings().send({ from: account });
      notify(0, `withdraw success`);
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const addToWhitelist = async () => {
    if (!account) {
      notify(1, "Wallet not connected");
      return;
    }
    try {
      const whitelist = whitelistText.split(/\r?\n/);
      let availableList = [];
      whitelist?.map((_address, index) => {
        if (Web3.utils.isAddress(_address)) availableList.push(_address);
      })
      console.log(availableList);
      const poolContract = await getPoolContract(poolData.launchpadAddress, account);
      await poolContract.methods.addWhiteLists(availableList).send({ from: account });
      availableList?.map((_address, index) => {
        createWhitelist({ variables: { launchpadAddress: address, listedAddress: _address, chain: networkID } });
        notify(0, `You have added ${_address} to whitelist`);
      })
    } catch (err) {
      console.log(err)
      notify(2, "Something went wrong");
    }
  }

  const formatDuration = ms => {
    if (ms < 0) ms = -ms;
    const time = {
      day: Math.floor(ms / 86400000),
      hour: Math.floor(ms / 3600000) % 24,
      minute: Math.floor(ms / 60000) % 60
    };
    return Object.entries(time)
        .filter(val => val[1] !== 0)
        .map(([key, val]) => `${val} ${key}${val !== 1 ? 's' : ''}`)
        .join(', ');
  };

  useEffect(() => {
    if (loading || error) return;
    const _poolData = data.getLaunchpadByAddress[0];
    setPoolData(_poolData);
  }, [loading, error])

  useEffect(() => {
    console.log(poolData);
    if (!poolData) return;
    poolData.isCanceled
      ? setState(5)
      : moment(poolData.startTime).isAfter(moment())
        ? setState(1)
        : (moment(poolData.EndTime).isBefore(moment()) || poolData?.isEnded)
          ? setState(4)
          : poolData.soldAmount >= poolData.hardcap
            ? setState(3)
            : setState(2)
  }, [poolData])

  useEffect(() => {
    getContractData();
  }, [account])

  // useEffect(() => {
  //   getWhitelist();
  // }, [account, data1]);

  useEffect(() => {
    if (!poolData) return;
    setInterval(() => {
      const _now = moment().format("X");
      const _timeToStart = moment(poolData.startTime).format("X") - _now;
      const _timeToEnd = moment(poolData.endTime).format("X") - _now;
      setTimeToStart(_timeToStart);
      setTimeToEnd(_timeToEnd);
    }, 1000)
  }, [poolData])

  useEffect(() => setPage(23), [])

  return (
    <>
      <div className="back-div">
        <Link to="/">
          <MdKeyboardBackspace  style={{ float: 'left', fontSize: '30px' }}/>
        </Link>
      </div>
      <div className="pool-details">
        <div className="info">
          <div className="project-description">
            <div className="info-top">
                <img className="avatar" src={poolData?.logoImg} alt="avatar" />
                <div className="content">
                  <div className="top">
                    <div className="title">{poolData?.name}</div>
                    {state === 1 && <div className='sale-upcomming'><span/>Upcomming</div>}
                    {state === 2 && <div className='sale-live'><span/>Sale Live</div>}
                    {state === 3 && <div className='sale-filled'><span/>Filled</div>}
                    {state === 4 && <div className='sale-ended'><span/>Sale Ended</div>}
                    {state === 5 && <div className='sale-canceled'><span/>Canceled</div>}
                  </div>
                  <div className="middle">
                    {poolData?.website && <a href={poolData?.website} target="_blank"><img src={website} alt="website" /></a>}
                    {poolData?.facebook && <a href={poolData?.facebook} target="_blank"><img src={facebook} alt="facebook" /></a>}
                    {poolData?.twitter && <a href={poolData?.twitter} target="_blank"><img src={twitter} alt="twitter" /></a>}
                    {poolData?.github && <a href={poolData?.github} target="_blank"><img src={github} alt="github" /></a>}
                    {poolData?.telegram && <a href={poolData?.telegram} target="_blank"><img src={telegram} alt="telegram" /></a>}
                    {poolData?.instagram && <a href={poolData?.instagram} target="_blank"><img src={instagram} alt="instagram" /></a>}
                    {poolData?.discord && <a href={poolData?.discord} target="_blank"><img src={discord} alt="discord" /></a>}
                    {poolData?.reddit && <a href={poolData?.reddit} target="_blank"><img src={reddit} alt="reddid" /></a>}
                    {poolData?.youtube && <a href={poolData?.youtube} target="_blank"><img src={youtube} alt="youtube" /></a>}
                  </div>
                  <div className="bottom">
                    <div dangerouslySetInnerHTML={{ __html: poolData?.description }} />
                  </div>
                </div>
              </div>
            {
                poolData?.youtube &&
                <div className="youtube">
                  <iframe width="100%" style={{ aspectRatio: "16/9" }} src={poolData?.youtube} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
                </div>
            }
          </div>
          <div className="token-metrics">
            <Donut presale={poolData?.hardcap * poolData?.presaleRate / (100 - 2) * 100} liquidity={poolData?.hardcap * poolData?.listingRate * poolData?.liquidityPercent / 100} team={poolData?.totalVestingTokens} totalSupply={poolData?.totalSupply / Math.pow(10, poolData?.tokenDecimals)} />
          </div>
          <div className="basic-info">
            <div className="content">
              <div className="info-item">
                <div className="key">Token Address</div>
                <div className="value">{poolData?.tokenAddress}</div>
              </div>
              <div className="info-item">
                <div className="key">Presale Start</div>
                <div className="value">{new Date(poolData?.startTime + "Z").toLocaleString()} (local time)</div>
              </div>
              <div className="info-item">
                <div className="key">Presale Duration</div>
                <div className="value">{formatDuration((new Date(poolData?.endTime)) - (new Date(poolData?.startTime)))}</div>
              </div>
              <div className="info-item">
                <div className="key">Presale Discount</div>
                <div className="value">{((1 - (poolData?.listingRate / poolData?.presaleRate)) * 100).toFixed(2)}%</div>
              </div>
              <div className="info-item">
                <div className="key">Liquidity Percent</div>
                <div className="value">{poolData?.liquidityPercent.toFixed(2)}%</div>
              </div>
              <div className="info-item">
                <div className="key">Liquidity Lockup Time</div>
                <div className="value">{poolData?.lockupTime} min after pool ends</div>
              </div>
              <div className="info-item">
                <div className="key">Listing on</div>
                <div className="value">{poolData?.router}</div>
              </div>
              <div className="info-item">
                <div className="key">Softcap</div>
                <div className="value">{(poolData?.softcap / poolData?.hardcap * 100).toFixed(2)}%</div>
              </div>
              <div className="info-item">
                <div className="key">Unsold Tokens handling</div>
                <div className="value">{poolData?.refundType}</div>
              </div>
              {/*<div className="info-item">
                <div className="key">Pool Address</div>
                <div className="value" style={{ color: "cyan" }}>{poolData?.launchpadAddress}</div>
              </div>
              <div className="info-item">
                <div className="key">Token Name</div>
                <div className="value">{poolData?.tokenName}</div>
              </div>
              <div className="info-item">
                <div className="key">Token Symbol</div>
                <div className="value">{poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Token Decimals</div>
                <div className="value">{poolData?.tokenDecimals}</div>
              </div>
              <div className="info-item">
                <div className="key">Total Supply</div>
                <div className="value">{poolData?.totalSupply / Math.pow(10, poolData?.tokenDecimals)} {poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Tokens for Presale</div>
                <div className="value">{poolData?.hardcap * poolData?.presaleRate / (100 - 2) * 100} {poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Tokens for Liquidity</div>
                <div className="value">{poolData?.hardcap * poolData?.listingRate * poolData?.liquidityPercent / 100} {poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Presale Rate</div>
                <div className="value">1 {poolData?.currency} = {poolData?.presaleRate} {poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Listing Rate</div>
                <div className="value">1 {poolData?.currency} = {poolData?.listingRate} {poolData?.tokenSymbol}</div>
              </div>
              <div className="info-item">
                <div className="key">Softcap</div>
                <div className="value">{poolData?.softcap} {poolData?.currency}</div>
              </div>
              <div className="info-item">
                <div className="key">Hardcap</div>
                <div className="value">{poolData?.hardcap} {poolData?.currency}</div>
              </div>
              <div className="info-item">
                <div className="key">Presale End</div>
                <div className="value">{poolData?.endTime} (UTC)</div>
              </div>*/}
            </div>
          </div>
          {
            poolData?.isWhitelisted &&
            <div className="whitelist">
              <div className="title">Whitelist({whitelistData?.data?.getWhitelist?.length})</div>
              <div className="list-line">
                <div className="left" style={{ color: "cyan" }}>No</div>
                <div className="right" style={{ color: "cyan" }}>Address</div>
              </div>
              {
                whitelistData?.data?.getWhitelist?.map((item, index) => (
                  <div className="list-line">
                    <div className="left">{index + 1}</div>
                    <div className="right">{item.listedAddress}</div>
                  </div>
                ))
              }
              {
                poolData?.owner === account &&
                <div className="add-box">
                  <div className="input-box">
                    <textarea placeholder="0x..." value={whitelistText} onChange={(e) => setWhitelistText(e.target.value)} />
                  </div>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <button className="default-btn" onClick={addToWhitelist}>Add to whitelist</button>
                  </div>
                </div>
              }

            </div>
          }
        </div>
        <div className="actions">
          <div className="status-1">
            <div className="title">Token Sale Progress</div>
            {state === 1 && <div className='time-state'>Sale Starts In: <span>{convertTime(timeToStart)}</span></div>}
            {state === 2 && !poolData?.isEnded && <div className='time-state'>Sale Ends In: <span>{convertTime(timeToEnd)}</span></div>}
            {poolData?.isEnded && <div className='time-state'>Sale Ended At: <span>{contractData?.finalizeTime ? moment(contractData.finalizeTime, 'X').format('YYYY : MM : DD hh : mm : ss') : poolData?.endTime}</span></div>}
            <Progress progress={poolData?.soldAmount * 100 / poolData?.hardcap} />
            <div className="progress-detail">
              <span>{poolData?.soldAmount} {poolData?.currency}</span>
              <span>{poolData?.hardcap} {poolData?.currency}</span>
            </div>
            {state === 2 &&
              <div className="buy-box">
                <div className="title">Amount</div>
                <div className="input-box">
                  <input placeholder="0.0" value={buyAmount} onChange={(e) => setBuyAmount(e.target.value)} />
                  <div className="max-btn" onClick={getMaxAmount}>MAX</div>
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <button className="default-btn" onClick={buy}>Buy with {poolData?.currency}</button>
                </div>
              </div>}
            {state === 5 && <div className="tips">This pool has canceled.</div>}
          </div>
          <div className="status-2">
            <div className="option-item">
              <div className="key">Status</div>
              <div className="value" style={{ color: "cyan" }}>
                {state === 1 && "Upcoming"}
                {state === 2 && "In Progress"}
                {state === 3 && "Filled"}
                {state === 4 && "Sale Ended"}
                {state === 5 && "Canceled"}
              </div>
            </div>
            <div className="option-item">
              <div className="key">Sale Type</div>
              <div className="value">{poolData?.isWhitelisted ? "Whitelist Only" : "Public"}</div>
            </div>
            <div className="option-item">
              <div className="key">Minimum Buy</div>
              <div className="value">{poolData?.minBuy} {poolData?.currency}</div>
            </div>
            <div className="option-item">
              <div className="key">Maximum Buy</div>
              <div className="value">{poolData?.maxBuy} {poolData?.currency}</div>
            </div>
            <div className="option-item">
              <div className="key">You Purchased</div>
              <div className="value">{contractData?.purchaseAmount / 1e18 || 0} {poolData?.currency}</div>
            </div>
          </div>
          {poolData?.isVesting &&
            <div className="status-3">
            <div className="title">Token Vestings</div>
            <div className="option-item">
              <div className="key">Your balance</div>
              <div className="value">{tokenData?.balance / 1e18 || 0} {poolData?.tokenSymbol}</div>
            </div>
            <div className="option-item">
              <div className="key">Current Vesting Cycle</div>
              <div className="value">{Math.min(Number(moment().diff(moment(contractData?.finalizeTime, 'X'), 'minutes')) / poolData?.vestingPeriod, (100 - poolData?.firstRelease) / poolData?.presaleTokenRelease) || 0}/{(100 - poolData?.firstRelease) / poolData?.presaleTokenRelease}</div>
            </div>
            <div className="option-item">
              <div className="key">First Token Release</div>
              <div className="value">{poolData?.firstRelease}%</div>
            </div>
            <div className="option-item">
              <div className="key">Vesting Peroid</div>
              <div className="value">{poolData?.vestingPeriod} min</div>
            </div>
            <div className="option-item">
              <div className="key">Token Release for each Cycle</div>
              <div className="value">{poolData?.presaleTokenRelease}%</div>
            </div>
            {(!poolData?.isEnded && poolData?.owner != account) &&
              <div className="option-item">
                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>Pool is not finalized by owner.</div>
              </div>
            }
            {poolData?.owner === account && !poolData?.isEnded && (state === 3 || poolData?.soldAmount >= poolData?.softcap) &&
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <button className="default-btn" onClick={finalize}>Finalize Pool</button>
              </div>
            }
            {
              poolData?.isEnded &&
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <button className="default-btn" onClick={claim}>Claim Vestings</button>
              </div>
            }
          </div>
          }
          {poolData?.isTeamVesting &&
            <div className="status-4">
            <div className="title">Team Vestings</div>
            <div className="option-item">
              <div className="key">Your balance</div>
              <div className="value">{tokenData?.balance / 1e18 || 0} {poolData?.tokenSymbol}</div>
            </div>
            <div className="option-item">
              <div className="key">Current Vesting Cycle</div>
              {
                (moment().diff(moment(contractData?.finalizeTime + poolData?.lockupTime * 60, 'X')) > 0)
                  ? <div className="value">{Math.min(Number(moment().diff(moment(contractData?.finalizeTime + poolData?.lockupTime * 60, 'X'), 'minutes')) / poolData?.teamVestingPeriod, (100 - poolData?.firstReleasePercent) / poolData?.teamTokenRelease)}/{(100 - poolData?.firstReleasePercent) / poolData?.teamTokenRelease}</div>
                  : <div className="value">Not started</div>
              }
            </div>
            <div className="option-item">
              <div className="key">Total Team Vesting Tokens</div>
              <div className="value">{poolData?.totalVestingTokens} {poolData.tokenSymbol}</div>
            </div>
            <div className="option-item">
              <div className="key">First Token Release after Listing</div>
              <div className="value">{poolData?.firstReleaseMinutes} min</div>
            </div>
            <div className="option-item">
              <div className="key">First Release</div>
              <div className="value">{poolData?.firstReleasePercent}%</div>
            </div>
            <div className="option-item">
              <div className="key">Vesting Peroid</div>
              <div className="value">{poolData?.teamVestingPeriod} min</div>
            </div>
            <div className="option-item">
              <div className="key">Token Release for each Cycle</div>
              <div className="value">{poolData?.teamTokenRelease}%</div>
            </div>

            {(!poolData?.isEnded && poolData?.owner != account) &&
              <div style={{ width: "100%", display: "flex", justifyContent: "left" }}>Pool is not finalized by owner.</div>
            }
            {poolData?.owner === account && !poolData?.isEnded && (state === 3 || poolData?.soldAmount >= poolData?.softcap) &&
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <button className="default-btn" onClick={finalize}>Finalize Pool</button>
              </div>
            }
            {
              poolData?.isEnded &&
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <button className="default-btn" onClick={withdrawTeamVestings}>Withdraw Team Vestings</button>
              </div>
            }
          </div>
          }
        </div>
      </div>
    </>
  )
}