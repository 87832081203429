import { gql } from "@apollo/client";

export const CREATE_STANDARD_TOKEN = gql`
    mutation createStandardToken(
        $name: String!, 
        $symbol: String!, 
        $decimals: Int!, 
        $totalSupply: Int!, 
        $isAntiBot: Boolean!, 
        $tokenAddress: String!, 
        $creatorAddress: String!,
        $chain: Int!
        ) {
        createStandardToken(
            name: $name, 
            symbol: $symbol, 
            decimals: $decimals, 
            totalSupply: $totalSupply, 
            isAntiBot: $isAntiBot, 
            tokenAddress: $tokenAddress, 
            creatorAddress: $creatorAddress,
            chain: $chain
        ) {
            standardToken {
                name
                symbol
                decimals
                totalSupply
                isAntiBot
                tokenAddress
                creatorAddress
                chain
            }
        }
    }
`;

export const CREATE_LIQUIDITY_GENERATOR_TOKEN = gql`
    mutation createLiquidityGeneratorToken(
        $name: String!
        $symbol: String!
        $totalSupply: Int!
        $router: String!
        $yieldFee: Float!
        $liquidityFee: Float!
        $marketingAddress: String!
        $marketingPercent: Float!
        $isAntiBot: Boolean!
        $tokenAddress: String!
        $creatorAddress: String!
        $chain: Int!
        ) {
        createLiquidityGeneratorToken(
            name: $name
            symbol: $symbol
            totalSupply: $totalSupply
            router: $router
            yieldFee: $yieldFee
            liquidityFee: $liquidityFee
            marketingAddress: $marketingAddress
            marketingPercent: $marketingPercent
            isAntiBot: $isAntiBot
            tokenAddress: $tokenAddress
            creatorAddress: $creatorAddress
            chain: $chain
        ) {
            liquidityGeneratorToken {
                name
                symbol
                totalSupply
                router
                yieldFee
                liquidityFee
                marketingAddress
                marketingPercent
                isAntiBot
                tokenAddress
                creatorAddress
                chain
            }
        }
    }
`;

export const CREATE_BABY_TOKEN = gql`
    mutation createBabyToken(
        $name: String!
        $symbol: String!
        $totalSupply: Int!
        $router: String!
        $rewardToken: String!
        $minimumTokenBalance: Int!
        $tokenRewardFee: Float!
        $autoAddLiquidity: Float!
        $marketingFee: Float!
        $marketingWallet: String!
        $isAntiBot: Boolean!
        $tokenAddress: String!
        $creatorAddress: String!
        $chain: Int!
        ) {
        createBabyToken(
            name: $name
            symbol: $symbol
            totalSupply: $totalSupply
            router: $router
            rewardToken: $rewardToken
            minimumTokenBalance: $minimumTokenBalance
            tokenRewardFee: $tokenRewardFee
            autoAddLiquidity: $autoAddLiquidity
            marketingFee: $marketingFee
            marketingWallet: $marketingWallet
            isAntiBot: $isAntiBot
            tokenAddress: $tokenAddress
            creatorAddress: $creatorAddress
            chain: $chain
        ) {
            babyToken {
                name
                symbol
                totalSupply
                router
                rewardToken
                minimumTokenBalance
                tokenRewardFee
                autoAddLiquidity
                marketingFee
                marketingWallet
                isAntiBot
                tokenAddress
                creatorAddress
                chain
            }
        }
    }
`;

export const CREATE_BUYBACK_BABY_TOKEN = gql`
    mutation createBuybackBabyToken(
        $name: String!
        $symbol: String!
        $totalSupply: Int!
        $router: String!
        $rewardToken: String!
        $liquidityFee: Float!
        $buybackFee: Float!
        $reflectionFee: Float!
        $marketingFee: Float!
        $isAntiBot: Boolean!
        $tokenAddress: String!
        $creatorAddress: String!
        $chain: Int!
        ) {
        createBuybackBabyToken(
            name: $name
            symbol: $symbol
            totalSupply: $totalSupply
            router: $router
            rewardToken: $rewardToken
            liquidityFee: $liquidityFee
            buybackFee: $buybackFee
            reflectionFee: $reflectionFee
            marketingFee: $marketingFee
            isAntiBot: $isAntiBot
            tokenAddress: $tokenAddress
            creatorAddress: $creatorAddress
            chain: $chain
        ) {
            buybackBabyToken {
                name
                symbol
                totalSupply
                router
                rewardToken
                liquidityFee
                buybackFee
                reflectionFee
                marketingFee
                isAntiBot
                tokenAddress
                creatorAddress
                chain
            }
        }
    }
`;

export const CREATE_LAUNCHPAD = gql`
    mutation createLaunchpad (
        $name: String!
        $owner: String!
        $launchpadAddress: String!
        $tokenAddress: String!
        $tokenName: String!
        $tokenSymbol: String!
        $tokenDecimals: Int!
        $totalSupply: Float!
        $currency: String!
        $isWhitelisted: Boolean!
        $isFair: Boolean!
        $sellingAmount: Float!
        $needAmount: Float!
        $presaleRate: Float!
        $listingRate: Float!
        $softcap: Float!
        $hardcap: Float!
        $refundType: String!
        $startTime: String!
        $endTime: String!
        $router: String!
        $liquidityPercent: Float!
        $lockupTime: Int!
        $maxBuy: Float!
        $minBuy: Float!
        $isVesting: Boolean!
        $firstRelease: Float
        $vestingPeriod: Int
        $presaleTokenRelease: Float
        $isTeamVesting: Boolean!
        $totalVestingTokens: Float
        $firstReleaseMinutes: Int
        $firstReleasePercent: Float
        $teamVestingPeriod: Int
        $teamTokenRelease: Float
        $logoImg: String!
        $website: String!
        $facebook: String
        $twitter: String
        $github: String
        $telegram: String
        $instagram: String
        $discord: String
        $reddit: String
        $youtube: String
        $description: String!
        $soldAmount: Float
        $chain: Int!
    ) {
        createLaunchpad (
            name: $name
            owner: $owner
            launchpadAddress: $launchpadAddress
            tokenAddress: $tokenAddress
            tokenName: $tokenName
            tokenSymbol: $tokenSymbol
            tokenDecimals: $tokenDecimals
            totalSupply: $totalSupply
            currency: $currency
            isWhitelisted: $isWhitelisted
            isFair: $isFair
            sellingAmount: $sellingAmount
            needAmount: $needAmount
            presaleRate: $presaleRate
            listingRate: $listingRate
            softcap: $softcap
            hardcap: $hardcap
            refundType: $refundType
            startTime: $startTime
            endTime: $endTime
            router: $router
            liquidityPercent: $liquidityPercent
            lockupTime: $lockupTime
            maxBuy: $maxBuy
            minBuy: $minBuy
            isVesting: $isVesting
            firstRelease: $firstRelease
            vestingPeriod: $vestingPeriod
            presaleTokenRelease: $presaleTokenRelease
            isTeamVesting: $isTeamVesting
            totalVestingTokens: $totalVestingTokens
            firstReleaseMinutes: $firstReleaseMinutes
            firstReleasePercent: $firstReleasePercent
            teamVestingPeriod: $teamVestingPeriod
            teamTokenRelease: $teamTokenRelease
            logoImg: $logoImg
            website: $website
            facebook: $facebook
            twitter: $twitter
            github: $github
            telegram: $telegram
            instagram: $instagram
            discord: $discord
            reddit: $reddit
            youtube: $youtube
            description: $description
            soldAmount: $soldAmount
            chain: $chain
        ) {
            launchpad {
                name
                owner
                launchpadAddress
                tokenAddress
                tokenName
                tokenSymbol
                tokenDecimals
                totalSupply
                currency
                isWhitelisted
                isFair
                sellingAmount
                needAmount
                presaleRate
                listingRate
                softcap
                hardcap
                refundType
                startTime
                endTime
                router
                liquidityPercent
                lockupTime
                maxBuy
                minBuy
                isVesting
                firstRelease
                vestingPeriod
                presaleTokenRelease
                isTeamVesting
                totalVestingTokens
                firstReleaseMinutes
                firstReleasePercent
                teamVestingPeriod
                teamTokenRelease
                logoImg
                website
                facebook
                twitter
                github
                telegram
                instagram
                discord
                reddit
                youtube
                description
                soldAmount
                chain
            }
        }
    }
`

export const CREATE_WHITELIST = gql`
    mutation createWhitelist(
        $launchpadAddress: String!
        $listedAddress: String!
        $chain: Int!
        ) {
        createWhitelist (
            launchpadAddress: $launchpadAddress
            listedAddress: $listedAddress
            chain: $chain
        ) {
            whitelist {
                launchpadAddress
                listedAddress
                chain
            }
        }
    }
`

export const UPDATE_SOLD_AMOUNT = gql`
    mutation updateSoldAmount(
        $launchpadAddress: String!
        $soldAmount: Float!
        ) {
        updateSoldAmount(
            launchpadAddress: $launchpadAddress
            soldAmount: $soldAmount
        ) {
            message
        }
    }
`

export const CANCEL_LAUNCHPAD = gql`
    mutation cancelLaunchpad($launchpadAddress: String!) {
        cancelLaunchpad(launchpadAddress: $launchpadAddress) {
            message
        }
    }
`

export const FINALIZE_LAUNCHPAD = gql`
    mutation finalizeLaunchpad($launchpadAddress: String!, $chain: Int!) {
        finalizeLaunchpad(launchpadAddress: $launchpadAddress, chain: $chain) {
            message
        }
    }
`

export const CREATE_APPROVAL = gql`
    mutation createApproval($launchpadAddress: String!, $chain: Int!) {
        createApproval(launchpadAddress: $launchpadAddress, chain: $chain) {
            approval {
            launchpadAddress
            approved
            }
        }
    }
`