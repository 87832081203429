import React, { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import moment from 'moment';
import avatar from "../../../assets/img/launchpad/avatar.png"
import LaunchpadCard from '../../../components/LaunchpadCard';
import { notify } from '../../../utils/notifyFunctions';

import { GET_LAUNCHPAD_BY_OWNER } from "../../../graphql/Launchpad/Queries";

export default function MyContributions({ account, networkID }) {

  const [launchpadListData, setLaunchpadListData] = useState(null);
  const [filter, setFilter] = useState(0);

  const { data, loading, error } = useQuery(GET_LAUNCHPAD_BY_OWNER, {
    variables: {
      owner: account,
      chain: networkID
    }
  });

  const listlaunchpads = () => {
    if (loading || error) return;

    let temp = [];
    data.getLaunchpadByOwner?.map((item, index) => {
      let state;
      moment(item.startTime).isAfter(moment())
        ? state = 1
        : moment(item.endTime).isBefore(moment())
          ? state = 4
          : item.soldAmount >= item.hardcap
            ? state = 3
            : state = 2
      if (item.isFair) {
        temp[index] = {
          address: item.launchpadAddress,
          img: item.logoImg,
          tokenName: item.tokenName,
          state: state,
          isFair: item.isFair,
          tokenRate: item.listingRate,
          tokenSymbol: item.tokenSymbol,
          priceFirst: item.softcap,
          priceSecond: null,
          priceThird: item.soldAmount,
          liquidity: item.liquidityPercent,
          lockTime: item.lockupTime,
          startTime: item.startTime,
          endTime: item.endTime
        };
      } else {
        temp[index] = {
          address: item.launchpadAddress,
          img: item.logoImg,
          tokenName: item.tokenName,
          state: state,
          isFair: item.isFair,
          tokenRate: item.listingRate,
          tokenSymbol: item.tokenSymbol,
          priceFirst: item.hardcap,
          priceSecond: item.softcap,
          priceThird: item.soldAmount,
          liquidity: item.liquidityPercent,
          lockTime: item.lockupTime,
          startTime: item.startTime,
          endTime: item.endTime
        };
      }
    })
    setLaunchpadListData(temp);
    console.log(temp);
  }

  useEffect(() => {
    console.log(data)
    listlaunchpads();
  }, [loading, error])

  if (loading) return (
    <div>Loading...</div>
  )

  if (error) {
    if (account === null) {
      notify(1, "Wallet not connected");
      return <div>Wallet not connected!</div>
    }
    return (
      <div>Fetch data error!</div>
    )
  }

  return (
    <>
      <div className="filter">
        <label className="text-white"><div>Filter: </div></label>
        <select className="text-input" value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value={0}>All</option>
          <option value={1}>Approved</option>
          <option value={2}>Audited</option>
          <option value={3}>Doxxed</option>
          <option value={4}>Kyced</option>
        </select>
      </div>
      <div className="list-container">
        {launchpadListData?.map((element, index) => {
          if (filter == 0) return (
            <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} />
          )
          if (filter == 1) return (
            element?.approved &&
            <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} />
          )
          if (filter == 2) return (
            element?.audited &&
            <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} />
          )
          if (filter == 3) return (
            element?.doxxed &&
            <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} />
          )
          if (filter == 4) return (
            element?.kyced &&
            <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} />
          )
        })}
      </div>
    </>

  )
}
