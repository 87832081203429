import { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ToastContainer } from 'react-toastify';
import 'antd/dist/antd.css';
import './App.css';
import "./assets/styles/styles.scss";
import Layout from './layouts';
import Dashboard from './pages/Dashboard';
import Staking from './pages/Staking';
import CreateLaunchpad from './pages/Launchpad/CreateLaunchpad/CreateLaunchpad';
import CreateFairLaunch from "./pages/Launchpad/CreateFairLaunch/CreateFairLaunch";
import CreateToken from "./pages/Launchpad/CreateToken/CreateToken";
import LaunchpadList from "./pages/Launchpad/LaunchpadList/LaunchpadList";
import PoolDetails from "./pages/Launchpad/LaunchpadList/PoolDetails";
import StakingPoolList from "./pages/Staking/StakingPoolList";
import CreateStakingPool from "./pages/Staking/CreateStakingPool";
import StakingPoolDetails from "./pages/Staking/StakingPoolDetails";

function App() {
  const [account, setAccount] = useState(null);
  const [page, setPage] = useState(0);
  const [networkID, setNetworkID] = useState(32520);

  const client = new ApolloClient({
    uri: "https://icepadbackend.icecreamswap.com/",
    cache: new InMemoryCache(),
  });

  return (
    <>
      <ApolloProvider client={client}>
        <Layout account={account} setAccount={setAccount} page={page} networkID={networkID} setNetworkID={setNetworkID}>
          <Routes>
            {/*<Route index element={<Dashboard account={account} setPage={setPage} networkID={networkID} />} />*/}
            {/*<Route path="create_staking_pool" element={<CreateStakingPool account={account} setPage={setPage} networkID={networkID} />} />
            <Route path="staking_pool_list" element={<StakingPoolList account={account} setPage={setPage} networkID={networkID} />} />
            <Route path="staking_pool/:poolId" element={<StakingPoolDetails account={account} setPage={setPage} networkID={networkID} />} />*/}
            <Route path="create_launchpad" element={<CreateLaunchpad account={account} setPage={setPage} networkID={networkID} />} />
            {/*<Route path="create_fair_launch" element={<CreateFairLaunch account={account} setPage={setPage} networkID={networkID} />} />*/}
            <Route path="create_token" element={<CreateToken account={account} setPage={setPage} networkID={networkID} />} />
            <Route index element={<LaunchpadList account={account} setPage={setPage} networkID={networkID} />} />
            <Route path="pool/:address" element={<PoolDetails account={account} setPage={setPage} networkID={networkID} />} />
          </Routes>
        </Layout>
        <ToastContainer autoClose={2000} />
      </ApolloProvider>
    </>
  );
}

export default App;