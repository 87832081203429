import React from 'react'

export default function TextInput({type, title, isRequired, value, setValue, placeholder, warning, tips }) {

    return (
        <div className="text-input-item">
            <label className="text-white"><div>{title}{isRequired && <span>*</span>}</div></label>
            <input className="text-input" type={type? type: "text"} value={value} onChange={(e) => setValue(e.target.value)} placeholder={placeholder}></input>
            {warning === 1 && <div className="warning-text">This field is required</div>}
            {warning === 2 && <div className="warning-text">Invalid value, expected number</div>}
            {warning === 3 && <div className="warning-text">Invalid address</div>}
            {warning === 4 && <div className="warning-text">Invalid value, out of range</div>}
            {warning === 5 && <div className="warning-text">Invalid value, expected integer</div>}
            {warning === 6 && <div className="warning-text">Pool already exist</div>}
            {tips && <div className="tips-text">{tips}</div>}
        </div>
    )
}
