import { useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import IconButton from "./IconButton";
import SwitchNetwork from "./SwitchNetwork";

// import ethIcon from "../assets/img/header/eth-icon.svg";
// import bscIcon from "../assets/img/header/bsc-icon.png";
import bitgertIcon from "../assets/img/header/bitgert-icon.png";

export const providerOptions = {
    walletconnect: {
        package: WalletConnect,
        options: {
            infuraId: "infura_project_id"
        }
    }
};

const web3Modal = new Web3Modal({
    providerOptions // required
});

console.log(web3Modal);

function ConnectWallet(props) {
    const [isNetworkWindowOpened, setIsNetworkWindowOpened] = useState(false);

    const openCloseNetworkWindows = () => {
        setIsNetworkWindowOpened((prevIsNetworkWindowOpened) => !prevIsNetworkWindowOpened)
    }

    const [address, setAddress] = useState();
    const [account, setAccount] = useState("Connect");
    const [balance, setBalance] = useState();
    const [networkID, setNetworkID] = useState(32520);
    const [networkName, setNetworkName] = useState("Bitgert");
    const [networkCurrerncy, setNetworkCurrency] = useState("Brise");
    const [networkSymbol, setNetworkSymbol] = useState(bitgertIcon);

    const connectWallet = async () => {
        try {
            const _provider = await web3Modal.connect();
            await connectNetwork(_provider, networkID);
            const web3 = new Web3(_provider);
            const _address = await web3.eth.getAccounts();
            props.setAccount(_address[0]);
            setAddress(_address[0]);
            setAccount(_address[0].slice(0, 6) + "..." + _address[0].slice(_address[0].length - 5, _address[0].length));
            const _balance = await web3.eth.getBalance(_address[0]);
            const _balance_ether = web3.utils.fromWei(_balance, 'ether');
            setBalance(_balance_ether);
        } catch (error) {
            console.log(error);
        }
    };

    const disconnectWallet = async () => {
        await web3Modal.clearCachedProvider();
        refreshState();
    }

    const refreshState = () => {
        setAccount("Connect");
        // setBalance(0);
    }

    const chooseNetwork = (_id, _name, _currency, _symbol) => {
        if (networkID === _id) return;
        setNetworkID(_id);
        setNetworkName(_name);
        setNetworkCurrency(_currency);
        setNetworkSymbol(_symbol);
        disconnectWallet();
    }

    const connectNetwork = async (_provider, _id) => {
        try {
            await _provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex(_id) }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                alert('Please add this chain id!');
            }
        }
    }

    useEffect(() => {
            connectWallet();
    }, [networkID])

    // const getNetwork = async () => {
    //     const network = await library.eth.getBlock();
    //     console.log(network);
    // }

    return (
        <>
            <div className="switch-network-btn desktop" onClick={openCloseNetworkWindows}>
                <IconButton icon={networkSymbol} title={networkName} />
            </div>
            <div className="switch-network-btn mobile" onClick={openCloseNetworkWindows}>
                <img src={networkSymbol} />
            </div>
            <div className="connect-wallet-btn">
                <div className="main-button" onClick={account === "Connect" ? connectWallet : null}>
                    <IconButton icon={props.icon} title={account} />
                </div>
                {account === "Connect"
                    ? null
                    : <div className="dropdown">
                        <div>{balance} {networkCurrerncy}</div>
                        <div onClick={disconnectWallet}>Log out</div>
                    </div>
                }
            </div>
            {isNetworkWindowOpened ? <SwitchNetwork closeAction={openCloseNetworkWindows} chooseNetwork={chooseNetwork} /> : null}
        </>


    );
}


export default ConnectWallet;
