// todo: replace this file by launchpad.json config file

export const Lock_Addr = "0xf4293d4b913024f41118e7A602607F6765Dd336f";
export const PoolFactory_Addr = "0x2D4bF9eDEEDF67C914C5FB0F57fe61E9C1cd2C65";
export const FairPoolFactory_Addr = "";
export const Pool_Addr = "0x7F79A19E484248649E88E6C202BFBa24D14CB04d";
export const FairPool_Addr = "";
export const AntiBot_Addr = '0x76B0aB9B7eF8b39066478e73Ed4b2616495de0d7';
// export const TokenFactoryManager_Addr = '0xd2BF6e48EFE626eb1F4F624f4b602aB830e3BADf';

export const StandardTokenFactory_Addr = '0xeaA6833868509cFe0F14B60f578eF3fb09d27145';
export const LiquidityGeneratorTokenFactory_Addr = '0x7a854D19A2DEb4134896138A58311D7836dF7f80';
export const BabyTokenFactory_Addr = '0xc6Ca8a633CEf1323E523Bc9380Ab79Ec4aB38e60';
export const BuyBackBabyTokenFactory_Addr = '0xcE6Bcc60Ae564396f95194a971B62826d4446160';
export const AntiBotStandardTokenFactory_Addr = '0x72F471D9aE3e99B0b85cc84C96EA071837d7BcAc';
export const AntiBotLiquidityGeneratorTokenFactory_Addr = '0x99e278e07fCC52e7aAC2B464cCb344926607Dea7';
export const AntiBotBabyTokenFactory_Addr = '0x9aC41d3D8304679e2D727201F23c7aa3d42B24df';
export const AntiBotBuyBackBabyTokenFactory_Addr = '0xfB5587C8F21A7C2D83a7Fb444bf440B1B9cc595A';

export const Airdrop_Addr = '';

export const IcecreamRouter_Addr = '0xBb5e1777A331ED93E07cF043363e48d320eb96c4';