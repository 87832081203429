import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useMutation } from "@apollo/client";

import { CREATE_BUYBACK_BABY_TOKEN } from "../../../graphql/Launchpad/Mutation";
import { getBuyBackBabyTokenFactory, getAntiBotBuyBackBabyTokenFactory } from '../../../utils/contractHelpers';
import {
  BuyBackBabyTokenFactory_Addr,
  AntiBotBabyTokenFactory_Addr,
  AntiBot_Addr,
  AntiBotBuyBackBabyTokenFactory_Addr
} from '../../../utils/addressHelpers';
import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';

export default function BuybackBabyToken({ account, networkID }) {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [router, setRouter] = useState("IcecreamSwap");
  const [rewardToken, setRewardToken] = useState('');
  const [liquidityFee, setLiquidityFee] = useState("");
  const [buybackFee, setBuybackFee] = useState("");
  const [reflectionFee, setReflectionFee] = useState("");
  const [marketingFee, setMarketingFee] = useState("");
  const [isAntiBot, setIsAntiBot] = useState(false);

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  const [createBuybackBabyToken, { error }] = useMutation(CREATE_BUYBACK_BABY_TOKEN)

  const onCreateToken = async () => {
    if (!account) return;

    try {
      let temp = Web3.utils.toWei(totalSupply);
      let tokenAddress;
      setPending(true);
      if (!isAntiBot) {
        const contract = await getBuyBackBabyTokenFactory(BuyBackBabyTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          rewardToken,
          '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
          [liquidityFee, buybackFee, reflectionFee, marketingFee, 50],
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      else {
        const contract = await getAntiBotBuyBackBabyTokenFactory(AntiBotBuyBackBabyTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          rewardToken,
          '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
          [liquidityFee, buybackFee, reflectionFee, marketingFee, 50],
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      try {
        createBuybackBabyToken({ variables: { name: name, symbol: symbol, totalSupply: totalSupply, rewardToken: rewardToken, liquidityFee: liquidityFee, buybackFee: buybackFee, reflectionFee: reflectionFee, marketingFee: marketingFee, isAntiBot: isAntiBot, tokenAddress: tokenAddress, chain: networkID } });
      } catch (error) {
        console.log(error);
      }
      setPending(false);
    }
    catch (error) {
      setPending(false);
      console.log(error);
    }
  }

  useEffect(() => {
    let temp = [];
    temp[0] = (name.length === 0) ? 1 : 0;
    temp[1] = (symbol.length === 0) ? 1 : 0;
    temp[2] = (totalSupply.length === 0) ? 1 : (!isNumber(totalSupply) ? 2 : 0);
    temp[3] = Web3.utils.isAddress(rewardToken) ? 0 : 3;
    temp[4] = (liquidityFee.length === 0) ? 1 : (!isNumber(liquidityFee) ? 2 : (Number(liquidityFee) > 100 || Number(liquidityFee) < 0 ? 4 : 0));
    temp[5] = (buybackFee.length === 0) ? 1 : (!isNumber(buybackFee) ? 2 : (Number(buybackFee) > 100 || Number(buybackFee) < 0 ? 4 : 0));
    temp[6] = (reflectionFee.length === 0) ? 1 : (!isNumber(reflectionFee) ? 2 : (Number(reflectionFee) > 100 || Number(reflectionFee) < 0 ? 4 : 0));
    temp[7] = (marketingFee.length === 0) ? 1 : (!isNumber(marketingFee) ? 2 : (Number(marketingFee) > 100 || Number(marketingFee) < 0 ? 4 : 0));
    setCheckFlags(temp);
    setCreateFlag(!temp[0] * !temp[1] * !temp[2] * !temp[3] * !temp[4] * !temp[5] * !temp[6] * !temp[7]);
  }, [name, symbol, totalSupply, rewardToken, buybackFee, reflectionFee, liquidityFee, marketingFee])

  return (
    <>
      <TextInput
        title="Name"
        isRequired={true}
        value={name}
        setValue={setName}
        placeholder="Ex: Ethereum"
        warning={checkFlags[0]}
      />
      <TextInput
        title="Symbol"
        isRequired={true}
        value={symbol}
        setValue={setSymbol}
        placeholder="Ex: ETH"
        warning={checkFlags[1]}
      />
      <TextInput
        title="Total Supply"
        isRequired={true}
        value={totalSupply}
        setValue={setTotalSupply}
        placeholder="Ex: 100000000000"
        warning={checkFlags[2]}
      />
      <div className="text-input-item">
        <label htmlFor="router" className="text-white"><div>Router</div></label>
        <select id="router" className="text-input" value={router} onChange={(e) => setRouter(e.target.value)}>
          <option value="IcecreamSwap">IcecreamSwap</option>
        </select>
      </div>
      <div className="text-input-group">
        <TextInput
          title="Reward Token"
          isRequired={true}
          value={rewardToken}
          setValue={setRewardToken}
          placeholder="Ex: 0x..."
          warning={checkFlags[3]}
        />
        <TextInput
          title="Liquidity Fee (%)"
          isRequired={true}
          value={liquidityFee}
          setValue={setLiquidityFee}
          placeholder="Ex: 0~100"
          warning={checkFlags[4]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Buyback Fee (%)"
          isRequired={true}
          value={buybackFee}
          setValue={setBuybackFee}
          placeholder="Ex: 0~100"
          warning={checkFlags[5]}
        />
        <TextInput
          title="Reflection Fee (%)"
          isRequired={true}
          value={reflectionFee}
          setValue={setReflectionFee}
          placeholder="Ex: 0~100"
          warning={checkFlags[6]}
        />
      </div>
      <TextInput
        title="Marketing fee (%)"
        isRequired={true}
        value={marketingFee}
        setValue={setMarketingFee}
        placeholder="Ex: 0~100"
        warning={checkFlags[7]}
      />
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isAntiBot} onChange={(e) => setIsAntiBot(e.target.checked)} />
          <label htmlFor="currency-bnb">Implement Pink Anti-Bot System?</label>
        </div>
      </div>
      <div className="btn-group">
        <button className="default-btn" disabled={!createFlag || pending} onClick={onCreateToken}>Create Token</button>
      </div>
    </>
  )
}
