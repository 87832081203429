import React from 'react'

export default function LaunchInfoItem({title, info}) {
    return (
        <div className="info-item">
            <div className="title">{title}</div>
            <div className="info">{info}</div>
        </div>
    )
}
