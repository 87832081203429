export const toSpaceNumber = (generalNumber) => {
    let _number = generalNumber.toString().split(".");
    _number[0] = _number[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return _number.join(".");
}

export const toGeneralNumber = (spaceNumber) => {
    let _number = spaceNumber.split(" ").join("");
    return Number(_number);
}

export const isNumber = (num) => {
    return !isNaN(num);
}

export const copyToClipboard = (string) => {
    try {
        navigator.clipboard.writeText(string);
        return true;
    } catch {
        return false;
    }
}

export const convertTime = (_time) => {
    const _second = _time % 60;
    const _minute = (_time / 60) % 60;
    const _hour = (_time / 3600) % 60;
    const _day = (_time / 86400) % 24;
    return `${parseInt(_day)} : ${parseInt(_hour)} : ${parseInt(_minute)} : ${parseInt(_second)}`;
}