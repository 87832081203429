import React, { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client"
import moment from 'moment';
import avatar from "../../../assets/img/launchpad/avatar.png"
import LaunchpadCard from '../../../components/LaunchpadCard';

import { GET_ALL_LAUNCHPADS, GET_APPROVAL } from "../../../graphql/Launchpad/Queries";

export default function AllLaunchpads({ networkID }) {

    const [launchpadListData, setLaunchpadListData] = useState(null);
    const [filter, setFilter] = useState(0);

    const { data, loading, error } = useQuery(GET_ALL_LAUNCHPADS, {
        variables: {
            chain: networkID
        }
    });

    const approval = useQuery(GET_APPROVAL, {
        variables: {
            chain: networkID
        }
    })

    console.log(approval);

    const listlaunchpads = () => {
        if (loading || error) return;
        if (approval.loading || approval.error) return;

        let temp = [];
        data.getAllLaunchpads?.map((item, index) => {
            let state;
            item.isCanceled
                ? state = 5
                : moment(item.startTime).isAfter(moment())
                    ? state = 1
                    : moment(item.endTime).isBefore(moment())
                        ? state = 4
                        : item.soldAmount >= item.hardcap
                            ? state = 3
                            : state = 2
            let approved;
            let audited;
            let doxxed;
            let kyced;
            approval.data.getApproval?.map((i) => {
                if (item.launchpadAddress === i.launchpadAddress) {
                    approved = i.approved;
                    audited = i.audited;
                    doxxed = i.doxxed;
                    kyced = i.kyced;
                }
            })
            if (item.isFair) {
                temp[index] = {
                    address: item.launchpadAddress,
                    img: item.logoImg,
                    tokenName: item.tokenName,
                    state: state,
                    isFair: item.isFair,
                    tokenRate: item.listingRate,
                    tokenSymbol: item.tokenSymbol,
                    priceFirst: item.softcap,
                    priceSecond: null,
                    priceThird: item.soldAmount,
                    liquidity: item.liquidityPercent,
                    lockTime: item.lockupTime,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    approved: approved,
                    audited: audited,
                    doxxed: doxxed,
                    kyced: kyced
                };
            } else {
                temp[index] = {
                    address: item.launchpadAddress,
                    img: item.logoImg,
                    tokenName: item.tokenName,
                    state: state,
                    isFair: item.isFair,
                    tokenRate: item.listingRate,
                    tokenSymbol: item.tokenSymbol,
                    priceFirst: item.hardcap,
                    priceSecond: item.softcap,
                    priceThird: item.soldAmount,
                    liquidity: item.liquidityPercent,
                    lockTime: item.lockupTime,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    approved: approved,
                    audited: audited,
                    doxxed: doxxed,
                    kyced: kyced
                };
            }
        })
        setLaunchpadListData(temp);
        console.log(temp);
    }

    useEffect(() => {
        listlaunchpads();
    }, [loading, error, approval.loading])

    if (loading) return (
        <div>Loading...</div>
    )

    if (error) return (
        <div>Fetch data error!</div>
    )

    return (
        <>
            <div className="filter">
                <label className="text-white"><div>Filter: </div></label>
                <select className="text-input" value={filter} onChange={(e) => setFilter(e.target.value)}>
                    <option value={0}>All</option>
                    <option value={1}>Approved</option>
                    <option value={2}>Audited</option>
                    <option value={3}>Doxxed</option>
                    <option value={4}>Kyced</option>
                </select>
            </div>
            <div className="list-container">
                {launchpadListData?.map((element, index) => {
                    if(filter === 0) return (
                        <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} key={index} />
                    )
                    if(filter === 1) return (
                        element?.approved &&
                        <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} key={index} />
                    )
                    if(filter === 2) return (
                        element?.audited &&
                        <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} key={index} />
                    )
                    if(filter === 3) return (
                        element?.doxxed &&
                        <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} key={index} />
                    )
                    if(filter === 4) return (
                        element?.kyced &&
                        <LaunchpadCard address={element.address} img={element.img} state={element.state} isFair={element.isFair} tokenRate={element.tokenRate} tokenSymbol={element.tokenSymbol} tokenName={element.tokenName} priceState={element.priceState} priceFirst={element.priceFirst} priceSecond={element.priceSecond} priceThird={element.priceThird} liquidity={element.liquidity} lockTime={element.lockTime} startTime={element.startTime} endTime={element.endTime} key={index} />
                    )
                })}
            </div>
        </>

    )
}
