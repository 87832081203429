import React, { useEffect, useState } from 'react';
import StandardToken from './StandardToken';
import LiquidityGeneratorToken from './LiquidityGeneratorToken';
import BabyToken from "./BabyToken";
import BuybackBabyToken from "./BuybackBabyToken";
import StandardTokenList from './StandardTokenList';
import LiquidityGeneratorTokenList from './LiquidityGeneratorTokenList';
import BabyTokenList from './BabyTokenList';
import BuybackBabyTokenList from './BuybackBabyTokenList';
import {Button, Col, Row} from "antd";
import {ImList2} from "react-icons/im";
import {MdKeyboardBackspace} from "react-icons/md";
import {Link} from "react-router-dom";

export default function CreateToken({ account, setPage, networkID }) {
  const [tab, setTab] = useState(0);
  const [tokenType, setTokenType] = useState('Standard Token');
  console.log(account);

  useEffect(() => setPage(22), [])

  return (
    <div className="create-token">
      <Row style={{marginBottom : '30px'}}>
        <Col xl={12}>
          <Link to="/">
            <MdKeyboardBackspace  style={{ float: 'left', fontSize: '30px' }}/>
          </Link>
        </Col>
      </Row>
      <div className="tab-switcher desktop">
        <div className={tab === 0 ? "tab-active" : "tab"} onClick={() => setTab(0)}>Create Token</div>
        <div className={tab === 1 ? "tab-active" : "tab"} onClick={() => setTab(1)}>Manage Tokens</div>
      </div>
      {tab === 0 &&
        <div className="create-field">
          <div className="warning-text">(*) is required field.</div>
          <div className="text-input-item">
            <label htmlFor="token_type" className="text-white"><div>Token Type<span>*</span></div></label>
            <select id="token_type" className="text-input" value={tokenType} onChange={(e) => setTokenType(e.target.value)}>
              <option value="Standard Token">Standard Token</option>
                {/*<option value="Liquidity Generator Token">Liquidity Generator Token</option>
              <option value="Baby Token">Baby Token</option>
              <option value="Buyback Baby Token">Buyback Baby Token</option>*/}  // todo: add other token types back after fixing them
            </select>
            <div className="tip-text">Need 20M Brise to create new token</div>
          </div>
          <div className="div-line" />
          {tokenType === "Standard Token" &&
            <StandardToken account={account} networkID={networkID} />
          }

          {tokenType === "Liquidity Generator Token" &&
            <LiquidityGeneratorToken account={account} networkID={networkID} />
          }

          {tokenType === "Baby Token" &&
            <BabyToken account={account} networkID={networkID} />
          }

          {tokenType === "Buyback Baby Token" &&
            <BuybackBabyToken account={account} networkID={networkID} />
          }
        </div>
      }
      {tab === 1 &&
        <div className="manage-field">
          <div className="text-input-item">
            <label htmlFor="token_type" className="text-white"><div>Token Type<span>*</span></div></label>
            <select id="token_type" className="text-input" value={tokenType} onChange={(e) => setTokenType(e.target.value)}>
              <option value="Standard Token">Standard Token</option>
              <option value="Liquidity Generator Token">Liquidity Generator Token</option>
              <option value="Baby Token">Baby Token</option>
              <option value="Buyback Baby Token">Buyback Baby Token</option>
            </select>
          </div>
          <div className="div-line" />
          {tokenType === "Standard Token" &&
            <StandardTokenList networkID={networkID} />
          }
          {tokenType === "Liquidity Generator Token" &&
            <LiquidityGeneratorTokenList networkID={networkID} />
          }
          {tokenType === "Baby Token" &&
            <BabyTokenList networkID={networkID} />
          }
          {tokenType === "Buyback Baby Token" &&
            <BuybackBabyTokenList networkID={networkID} />
          }
        </div>
      }
    </div>

  )
}
