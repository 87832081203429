import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import moment, { isBefore, isAfter, isMoment } from 'moment';
import { DatePicker } from "antd";

import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';
import { getPoolFactoryContract } from '../../../utils/contractHelpers';
import { PoolFactory_Addr } from '../../../utils/addressHelpers';

import "antd/dist/antd.css";
import calendar from "../../../assets/img/launchpad/calendar.svg";

export default function Step2({ account, setStep,
  currency, tokenSymbol,
  tokenDecimals,
  presaleRate, setPresaleRate,
  whitelist, setWhitelist,
  softcap, setSoftcap,
  hardcap, setHardcap,
  minimumBuy, setMininumBuy,
  maximumBuy, setMaxinumBuy,
  refundType, setRefundType,
  router, setRouter,
  liquidity, setLiquidity,
  listingRate, setListingRate,
  startTime, setStartTime,
  startTimeString, setStartTimeString,
  endTime, setEndTime,
  endTimeString, setEndTimeString,
  liquidityLockup, setLiquidityLockup,
  isVesting, setIsVesting,
  isTeamVesting, setIsTeamVesting,
  firstRelease, setFirstRelease,
  vestingPeriod, setVestingPeriod,
  presaleTokenRelease, setPresaleTokenRelease,
  totalVestingTokens, setTotalVestingTokens,
  firstReleaseMinutes, setFirstReleaseMinutes,
  firstReleasePercent, setFirstReleasePercent,
  teamVestingPeriod, setTeamVestingPeriod,
  teamTokenRelease, setTeamTokenRelease,
  tokensForPresale, setTokensForPresale,
  tokensForLiquidity, setTokensForLiquidity,
  needAmount, setNeedAmount
}) {

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  useEffect(async () => {
    let temp = [];
    temp[0] = presaleRate.length === 0 ? 1 : !isNumber(presaleRate) ? 2 : Number(presaleRate) < 0 || Number(presaleRate) < Number(listingRate)? 4 : 0;
    temp[1] = softcap.length === 0 ? 1 : !isNumber(softcap) ? 2 : Number(softcap) <= 0 || Number(softcap) < Number(hardcap) / 2 || Number(softcap) > Number(hardcap) ? 4 : 0;
    temp[2] = hardcap.length === 0 ? 1 : !isNumber(hardcap) ? 2 : Number(hardcap) <= 0 || Number(softcap) < Number(hardcap) / 2 || Number(softcap) > Number(hardcap) ? 4 : 0;
    temp[3] = minimumBuy.length === 0 ? 1 : !isNumber(minimumBuy) ? 2 : Number(minimumBuy) <= 0 || Number(minimumBuy) > Number(maximumBuy) < 0 ? 4 : 0;
    temp[4] = maximumBuy.length === 0 ? 1 : !isNumber(maximumBuy) ? 2 : Number(maximumBuy) <= 0 || Number(minimumBuy) > Number(maximumBuy) < 0 ? 4 : 0;
    temp[5] = liquidity.length === 0 ? 1 : !isNumber(liquidity) ? 2 : Number(liquidity) <= 50 ? 4 : 0;
    temp[6] = listingRate.length === 0 ? 1 : !isNumber(listingRate) ? 2 : Number(listingRate) <= 0 ? 4 : 0;
    temp[7] = !isMoment(startTime) ? 1 : startTime.isBefore(moment()) ? 2 : startTime.isAfter(endTime) ? 3 : 0;
    temp[8] = !isMoment(endTime) ? 1 : endTime.isBefore(moment()) ? 2 : startTime.isAfter(endTime) ? 3 : 0;
    temp[9] = liquidityLockup.length === 0 ? 1 : !isNumber(liquidityLockup) ? 2 : Number(liquidityLockup) < 5 ? 4 : 0;
    temp[10] = firstRelease.length === 0 ? 1 : !isNumber(firstRelease) ? 2 : Number(firstRelease) <= 0 || Number(firstRelease) >= 100 || Number(firstRelease) + Number(presaleTokenRelease) > 100 ? 4 : 0;
    temp[11] = vestingPeriod.length === 0 ? 1 : !isNumber(vestingPeriod) ? 2 : Number(vestingPeriod) <= 0 ? 4 : 0;
    temp[12] = presaleTokenRelease.length === 0 ? 1 : !isNumber(presaleTokenRelease) ? 2 : Number(presaleTokenRelease) >= 100 || Number(presaleTokenRelease) <= 0 || Number(firstRelease) + Number(presaleTokenRelease) > 100 ? 4 : 0;
    temp[13] = totalVestingTokens.length === 0 ? 1 : !isNumber(totalVestingTokens) ? 2 : Number(totalVestingTokens) <= 0 ? 4 : 0;
    temp[14] = firstReleaseMinutes.length === 0 ? 1 : !isNumber(firstReleaseMinutes) ? 2 : Number(firstReleaseMinutes) <= 0 ? 4 : 0;
    temp[15] = firstReleasePercent.length === 0 ? 1 : !isNumber(firstReleasePercent) ? 2 : Number(firstReleasePercent) <= 0 || Number(firstReleasePercent) >= 100 || Number(firstReleasePercent) + Number(teamTokenRelease) > 100 ? 4 : 0;
    temp[16] = teamVestingPeriod.length === 0 ? 1 : !isNumber(teamVestingPeriod) ? 2 : Number(teamVestingPeriod) <= 0 ? 4 : 0;
    temp[17] = teamTokenRelease.length === 0 ? 1 : !isNumber(teamTokenRelease) ? 2 : Number(teamTokenRelease) >= 100 || Number(teamTokenRelease) <= 0 || Number(firstReleasePercent) + Number(teamTokenRelease) > 100 ? 4 : 0;
    const poolFactoryContract = await getPoolFactoryContract(PoolFactory_Addr, true);
    console.log(poolFactoryContract);
    if (temp[0] === 0 && temp[1] === 0 && temp[2] === 0 && temp[5] === 0 && temp[6] === 0) {
      const rateSetting = ['0x' + (presaleRate * Math.pow(10, tokenDecimals)).toString(16), '0x' + (listingRate * Math.pow(10, tokenDecimals)).toString(16)];
      const capSettings = ['0x' + (softcap * Math.pow(10, 18)).toString(16), '0x' + (hardcap * Math.pow(10, 18)).toString(16)];
      const _needAmount = await poolFactoryContract.methods.estimateTokenAmount(rateSetting, capSettings, liquidity, totalVestingTokens === ""? 0: '0x' + (totalVestingTokens * Math.pow(10, tokenDecimals)).toString(16)).call();
      setNeedAmount(Number(_needAmount)/Math.pow(10, tokenDecimals));
    }

    if (!isVesting) {
      temp[10] = 0;
      temp[11] = 0;
      temp[12] = 0;
    }
    if (!isTeamVesting) {
      temp[13] = 0;
      temp[14] = 0;
      temp[15] = 0;
      temp[16] = 0;
      temp[17] = 0;
    }

    setCheckFlags(temp);
    setCreateFlag(!temp[0] * !temp[1] * !temp[2] * !temp[3] * !temp[4] * !temp[5] * !temp[6] * !temp[7] * !temp[8] * !temp[9] * !temp[10] * !temp[11] * !temp[12] * !temp[13] * !temp[14] * !temp[15] * !temp[16] * !temp[17]);
  }, [presaleRate, hardcap, softcap, minimumBuy, maximumBuy, liquidity, listingRate, startTime, endTime, liquidityLockup, firstRelease, vestingPeriod, presaleTokenRelease, isVesting, isTeamVesting, totalVestingTokens, firstReleaseMinutes, firstReleasePercent, teamVestingPeriod, teamTokenRelease])

  return (
    <div className="step-2">
      <div className="warning-text">(*) is required field.</div>
      <TextInput
        title="Presale Rate"
        isRequired={true}
        value={presaleRate}
        setValue={setPresaleRate}
        placeholder="Ex: 100"
        warning={checkFlags[0]}
        tips={`If I spend 1 ${currency} how many tokens will I receive?`}
      />
      <div className="radio-input-item">
        <div className="text-white">Whitelist</div>
        <div>
          <input className="radio-input" type="radio" checked={whitelist === 0} onChange={() => setWhitelist(0)} />
          <label>Disable</label>
        </div>
        <div>
          <input className="radio-input" type="radio" checked={whitelist === 1} onChange={() => setWhitelist(1)} />
          <label>Enable</label>
        </div>
        <div className="tips-text">You can enable/disable whitelist anytime</div>
      </div>
      <div className="text-input-group">
        <TextInput
          title={`Softcap (${currency})`}
          isRequired={true}
          value={softcap}
          setValue={setSoftcap}
          placeholder="Ex: 10"
          warning={checkFlags[1]}
          tips="Softcap must be >= 50% of Hardcap!"
        />
        <TextInput
          title={`Hardcap (${currency})`}
          isRequired={true}
          value={hardcap}
          setValue={setHardcap}
          placeholder="Ex: 10"
          warning={checkFlags[2]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title={`Minimum Buy (${currency})`}
          isRequired={true}
          value={minimumBuy}
          setValue={setMininumBuy}
          placeholder="Ex: 0.1"
          warning={checkFlags[3]}
        />
        <TextInput
          title={`Maximum buy (${currency})`}
          isRequired={true}
          value={maximumBuy}
          setValue={setMaxinumBuy}
          placeholder="Ex: 0.2"
          warning={checkFlags[4]}
        />
      </div>
      <div className="text-input-group">
        <div className="text-input-item">
          <label className="text-white"><div>Refund Type</div></label>
          <select className="text-input" value={refundType} onChange={(e) => setRefundType(e.target.value)}>
            <option value={0}>Refund</option>
            <option value={1}>Burn</option>
          </select>
        </div>
        <div className="text-input-item">
          <label className="text-white"><div>Router</div></label>
          <select className="text-input" value={router} onChange={(e) => setRouter(e.target.value)}>
            <option value="IcecreamSwap">IcecreamSwap</option>
          </select>
        </div>
      </div>
      <div className="text-input-group">
        <TextInput
          title="Liquidity (%)"
          isRequired={true}
          value={liquidity}
          setValue={setLiquidity}
          placeholder="Ex: 52"
          warning={checkFlags[5]}
        />
        <TextInput
          title="Listing Rate"
          isRequired={true}
          value={listingRate}
          setValue={setListingRate}
          placeholder="Ex: 500"
          warning={checkFlags[6]}
          tips={`1 ${currency} = ${listingRate} ${tokenSymbol}`}
        />
      </div>
      <div className="text-input-item">
        <div className="tips-text">
          Enter the percentage of raised funds that should be allocated to Liquidity on (Min 51%, Max 100%)<br />
          If I spend 1 {currency} on how many tokens will I receive? Usually this amount is lower than presale rate to allow for a higher listing price on
        </div>
      </div>
      <div className="text-input-group">
        <div className="text-input-item">
          <label className="text-white"><div>Start time (UTC)<span className="warning-text">*</span></div></label>
          <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={true} minuteStep={30} secondStep={60} value={startTime} onChange={(date, dateString) => { setStartTime(date); setStartTimeString(dateString); }} suffixIcon={<img src={calendar} />} />
          {checkFlags[7] === 1 && <div className="warning-text">This field is required</div>}
          {checkFlags[7] === 2 && <div className="warning-text">Start time needs to be after now</div>}
          {checkFlags[7] === 3 && <div className="warning-text">Start time needs to be before End time</div>}
        </div>
        <div className="text-input-item">
          <label className="text-white"><div>End Time (UTC)<span className="warning-text">*</span></div></label>
          <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime={true} minuteStep={30} secondStep={60} value={endTime} onChange={(date, dateString) => { setEndTime(date); setEndTimeString(dateString); }} suffixIcon={<img src={calendar} />} />
          {checkFlags[8] === 1 && <div className="warning-text">This field is required</div>}
          {checkFlags[8] === 2 && <div className="warning-text">End time needs to be after now</div>}
          {checkFlags[8] === 3 && <div className="warning-text">End time needs to be after Start time</div>}
        </div>
      </div>
      <TextInput
        title="Liquidity Lockup (minutes)"
        isRequired={true}
        value={liquidityLockup}
        setValue={setLiquidityLockup}
        placeholder="Ex: 43200 (30 days)"
        warning={checkFlags[9]}
      />
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isVesting} onChange={(e) => setIsVesting(e.target.checked)} />
          <label>Using Vesting Contributor?</label>
        </div>
      </div>
      {
        isVesting
          ? <>
            <div className="text-input-item">
              <div className="tips-text">
                Vesting Contributor doesn't support rebase tokens.
              </div>
            </div>
            <TextInput
              title="First release for presale (%)"
              isRequired={true}
              value={firstRelease}
              setValue={setFirstRelease}
              placeholder="Ex: 40"
              warning={checkFlags[10]}
            />
            <div className="text-input-group">
              <TextInput
                title="Vesting period each cycle (minutes)"
                isRequired={true}
                value={vestingPeriod}
                setValue={setVestingPeriod}
                placeholder="Ex: 3"
                warning={checkFlags[11]}
              />
              <TextInput
                title="Presale token release each cycle (%)"
                isRequired={true}
                value={presaleTokenRelease}
                setValue={setPresaleTokenRelease}
                placeholder="Ex: 20"
                warning={checkFlags[12]}
              />
            </div>
          </>
          : null
      }
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isTeamVesting} onChange={(e) => setIsTeamVesting(e.target.checked)} />
          <label>Using Team Vesting?</label>
        </div>
      </div>
      {
        isTeamVesting
          ? <>
            <div className="text-input-item">
              <div className="tips-text">
                Team Vesting doesn't support rebase tokens.
              </div>
            </div>
            <div className="text-input-group">
              <TextInput
                title="Total team vesting tokens"
                isRequired={true}
                value={totalVestingTokens}
                setValue={setTotalVestingTokens}
                placeholder="Ex: 1000"
                warning={checkFlags[13]}
              />
              <TextInput
                title="First token release after listing (minutes)"
                isRequired={true}
                value={firstReleaseMinutes}
                setValue={setFirstReleaseMinutes}
                placeholder="Ex: 5"
                warning={checkFlags[14]}
              />
            </div>
            <TextInput
              title="First release for presale (%)"
              isRequired={true}
              value={firstReleasePercent}
              setValue={setFirstReleasePercent}
              placeholder="Ex: 40"
              warning={checkFlags[15]}
            />
            <div className="text-input-group">
              <TextInput
                title="Vesting period each cycle (minutes)"
                isRequired={true}
                value={teamVestingPeriod}
                setValue={setTeamVestingPeriod}
                placeholder="Ex: 5"
                warning={checkFlags[16]}
              />
              <TextInput
                title="Presale token release each cycle (%)"
                isRequired={true}
                value={teamTokenRelease}
                setValue={setTeamTokenRelease}
                placeholder="Ex: 20"
                warning={checkFlags[17]}
              />
            </div>
          </>
          : null
      }
      <div className="tips-text">Need {Number(needAmount).toFixed(2)} {tokenSymbol} to create launchpad.</div>
      <div className="btn-group">
        <button className="default-btn" onClick={() => setStep(prev => prev - 1)}>Back</button>
        <button className="default-btn" disabled={!createFlag || pending} onClick={() => setStep(prev => prev + 1)}>Next</button>
      </div>
    </div>
  )
}
