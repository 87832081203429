import React from 'react'
import crossIcon from "../assets/img/header/cross-icon.svg";
// import bscIcon from "../assets/img/header/bsc-icon.png";
// import ethIcon from "../assets/img/header/eth-icon.svg";
import bitgertIcon from "../assets/img/header/bitgert-icon.png";

export default function SwitchNetwork(props) {

    return (
        <div className="switch-network-backdrop">
            <div className="switch-network-window">
                <div className="title">
                    Choose Network
                    <img className="button" src={crossIcon} onClick={props.closeAction} />
                </div>
                <div className="divider" />
                <div className="content">
                    <div className="network button" onClick={() => {
                        props.chooseNetwork(32520, "Bitgert", "Brise", bitgertIcon);
                        props.closeAction()
                    }}>
                        <img src={bitgertIcon} />
                        <div>Bitgert</div>
                    </div>
                    {/*
                    <div className="network button" onClick={() => {
                        props.chooseNetwork(97, "BSC Testnet", "BNB", bscIcon);
                        props.closeAction()
                    }}>
                        <img src={bscIcon} />
                        <div>BSC Testnet</div>
                    </div>
                    <div className="network button" onClick={() => {
                        props.chooseNetwork(3, "Ropsten Test Network", "ETH", ethIcon);
                        props.closeAction()
                    }}>
                        <img src={ethIcon} />
                        <div>Ropsten Test Network</div>
                    </div>
                    */}
                </div>
            </div>
        </div >
    )
}
