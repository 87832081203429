import React from 'react';
import check from "../assets/img/launchpad/check.svg";

export default function Steps({step}) {
  return (
    <div className="steps">
        <Step id={1} activeStep={step + 1} title="Verify Token" description="Enter the token address and verify" />
        <Step id={2} activeStep={step + 1} title="DeFi Launchpad Info" description="Enter the launchpad information that you want to raise , that should be enter all details about your presale" />
        <Step id={3} activeStep={step + 1} title="Add Additional Info" description="Let people know who you are" />
        <Step id={4} activeStep={step + 1} title="Finish" description="Review your information" />
    </div>
  )
}

function Step({id, activeStep, title, description}) {
    return (
        <div className={id <= activeStep? "active-step": "step"}>
            <div className="step-number">{id < activeStep? <img src={check} />: id}</div>
            <div className="step-text">
                <div className="title">{title}</div>
                <div className="description">{description}</div>
            </div>
        </div>
    )
}
