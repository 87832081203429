import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '../components/IconButton';

import walletIcon from "../assets/img/header/wallet-icon.svg"
import iceCreamLogo2 from "../assets/img/header/icecream22.svg"
import ConnectWallet from '../components/ConnectWallet';
import {Image, Menu} from "antd";
import {IoIceCream, IoRocketSharp} from "react-icons/io5";
import siteLogo from "../assets/img/sidebar/sitelogo.png";

export default function Header({ account, setAccount }) {
    const [isFixed, setIsFixed] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', function () {
            window.pageYOffset > 0 ? setIsFixed(true) : setIsFixed(false);
        })
    }, [])

    const [current, setCurrent] = useState('presale');

    const onClick = (e) => {
        setCurrent(e.key);
    };

    return (
        <>
            <div className="header-left">
                <Link to="/">
                    <img src={siteLogo} style={{ width :'40px', marginLeft : '20px', marginTop : '20px', position: "absolute", zIndex:'1'}} alt="" />
                </Link>
            </div>
            <nav className={isFixed ? 'fixed-header desktop' : 'header desktop'}>

                <div className="header-right">
                    {/* <IconButton title="Create DAO" /> */}
                    <ConnectWallet icon={walletIcon} account={account} setAccount={setAccount} />
                    {/*<div className={"icon-button button" }>*/}
                    {/*    <div>*/}
                    {/*        <a rel="noopener noreferrer" href="https://app.icecreamswap.com/#/swap">*/}
                    {/*        <IoIceCream style={{ fontSize: '20px'}}/> Swap*/}
                    {/*    </a></div>*/}
                    {/*</div>*/}
                </div>
            </nav>
            <nav className="header-mobile mobile">

                <div className="header-right">
                    {/* <IconButton title="Create DAO" /> */}
                    <ConnectWallet account={account} setAccount={setAccount} />

                    {/*<div className={"icon-button button" }> <iceCreamLogo2/>*/}
                    {/*    <div>*/}
                    {/*        <a rel="noopener noreferrer" href="https://app.icecreamswap.com/#/swap">*/}
                    {/*            <IoIceCream style={{ fontSize: '20px'}}/> Swap*/}
                    {/*        </a></div>*/}
                    {/*</div>*/}
                </div>

            </nav>
        </>

    )
}
