import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { useMutation } from "@apollo/client";

import { CREATE_LIQUIDITY_GENERATOR_TOKEN } from "../../../graphql/Launchpad/Mutation";
import { getLiquidityGeneratorTokenFactory, getAntiBotLiquidityGeneratorTokenFactory } from '../../../utils/contractHelpers';
import { LiquidityGeneratorTokenFactory_Addr, AntiBotLiquidityGeneratorTokenFactory_Addr, AntiBot_Addr } from '../../../utils/addressHelpers';
import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';

export default function LiquidityGeneratorToken({ account, networkID }) {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [isAntiBot, setIsAntiBot] = useState(false);
  const [router, setRouter] = useState("IcecreamSwap");
  const [yieldFee, setYieldFee] = useState("");
  const [liquidityFee, setLiquidityFee] = useState("");
  const [marketingAddress, setMarketingAddress] = useState("");
  const [marketingPercent, setMarketingPercent] = useState("");

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  const [createLiquidityGeneratorToken, { error }] = useMutation(CREATE_LIQUIDITY_GENERATOR_TOKEN);

  const onCreateToken = async () => {
    if (!account) return;

    try {
      let temp = Web3.utils.toWei(totalSupply);
      let tokenAddress;
      setPending(true);

      if (!isAntiBot) {
        const contract = await getLiquidityGeneratorTokenFactory(LiquidityGeneratorTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
          marketingAddress,
          yieldFee,
          liquidityFee,
          marketingPercent,
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      } else {
        const contract = await getAntiBotLiquidityGeneratorTokenFactory(AntiBotLiquidityGeneratorTokenFactory_Addr, true);
        const receipt = await contract.methods.create(
          name,
          symbol,
          temp,
          "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
          marketingAddress,
          yieldFee,
          liquidityFee,
          marketingPercent,
          AntiBot_Addr
        ).send({ from: account, value: '0x' + Math.pow(10, 17).toString(16) });
        tokenAddress = receipt.events[0].address;
        console.log(receipt.events[0].address);
      }
      try {
        createLiquidityGeneratorToken({ variables: { name: name, symbol: symbol, totalSupply: totalSupply,yieldFee: yieldFee, liquidityFee: liquidityFee, marketingAddress: marketingAddress, marketingPercent: marketingPercent, isAntiBot: isAntiBot, tokenAddress: tokenAddress, chain: networkID } });
      } catch (error) {
        console.log(error);
      }
      setPending(false);
    }
    catch (error) {
      setPending(false);
      console.log(error);
    }
  }

  useEffect(() => {
    let temp = [];
    temp[0] = (name.length === 0) ? 1 : 0;
    temp[1] = (symbol.length === 0) ? 1 : 0;
    temp[2] = (totalSupply.length === 0) ? 1 : !isNumber(totalSupply) ? 2 :Number(marketingPercent) < 0 ? 4 : 0;
    temp[3] = (yieldFee.length === 0) ? 1 : (!isNumber(yieldFee) ? 2 :Number(marketingPercent) < 0 ? 4 : 0);
    temp[4] = (liquidityFee.length === 0) ? 1 : (!isNumber(liquidityFee) ? 2 : 0);
    temp[5] = Web3.utils.isAddress(marketingAddress) ? 0 : 3;
    temp[6] = (marketingPercent.length === 0) ? 1 : (!isNumber(marketingPercent) ? 2 : (Number(marketingPercent) > 25 || Number(marketingPercent) < 0 ? 4 : 0));
    setCheckFlags(temp);
    setCreateFlag(!temp[0] * !temp[1] * !temp[2] * !temp[3] * !temp[4] * !temp[5] * !temp[6]);
  }, [marketingAddress, marketingPercent, name, symbol, totalSupply, yieldFee, liquidityFee])

  return (
    <>
      <TextInput
        title="Name"
        isRequired={true}
        value={name}
        setValue={setName}
        placeholder="Ex: Ethereum"
        warning={checkFlags[0]}
      />
      <TextInput
        title="Symbol"
        isRequired={true}
        value={symbol}
        setValue={setSymbol}
        placeholder="Ex: ETH"
        warning={checkFlags[1]}
      />
      <TextInput
        title="Total Supply"
        isRequired={true}
        value={totalSupply}
        setValue={setTotalSupply}
        placeholder="Ex: 100000000000"
        warning={checkFlags[2]}
      />
      <div className="text-input-item">
        <label htmlFor="router" className="text-white"><div>Router</div></label>
        <select id="router" className="text-input" value={router} onChange={(e) => setRouter(e.target.value)}>
          <option value="IcecreamSwap">IcecreamSwap</option>
        </select>
      </div>
      <div className="text-input-group">
        <TextInput
          title="Transaction fee to generate yield (%)"
          isRequired={true}
          value={yieldFee}
          setValue={setYieldFee}
          placeholder="Ex: 1"
          warning={checkFlags[3]}
        />
        <TextInput
          title="Transaction fee to generate liquidity (%)"
          isRequired={true}
          value={liquidityFee}
          setValue={setLiquidityFee}
          placeholder="Ex: 1"
          warning={checkFlags[4]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Charity/Marketing address"
          isRequired={true}
          value={marketingAddress}
          setValue={setMarketingAddress}
          placeholder="Ex: 0x..."
          warning={checkFlags[5]}
        />
        <TextInput
          title="Charity/Marketing percent (%)"
          isRequired={true}
          value={marketingPercent}
          setValue={setMarketingPercent}
          placeholder="0~25"
          warning={checkFlags[6]}
        />
      </div>
      <div className="checkbox-input-item">
        <div>
          <input className="radio-input" type="checkbox" checked={isAntiBot} onChange={(e) => setIsAntiBot(e.target.checked)} />
          <label htmlFor="currency-bnb">Implement Pink Anti-Bot System?</label>
        </div>
      </div>
      <div className="btn-group">
        <button className="default-btn" disabled={!createFlag || pending} onClick={onCreateToken}>Create Token</button>
      </div>
    </>
  )
}
