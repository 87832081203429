import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

function Donut({ presale, liquidity, team, totalSupply }) {

    const [donutData, setDonutData] = useState([]);

    useEffect(() => {
        console.log("aaa")
        if (donutData[0]) return;
        const unlocked = totalSupply - presale - liquidity - team;
        const _donutData = [presale, liquidity, team, unlocked];
        setDonutData(_donutData)
    }, [presale, liquidity, team, totalSupply])

    const labels = ["presale", "liquidity", "team", "unlocked"]

    const data = {
        series: donutData,
        options: {
            legend: {
                show: true
            },
            responsive: [
                {
                    breakpoint: 600,
                    options: {
                        chart: {
                            width: "100%",
                            height: 250
                        },
                        legend: {
                            show: true
                        }
                    }
                },
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ],
            labels,
            // ...chartOptions
        },
    };
    // console.log(data);

    return (
        donutData[0] 
        ? <Chart options={data.options} series={data.series} type="donut" height={400} />
        : <></>
    )
}

export default Donut;