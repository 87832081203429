import launchpadContracts from "../contracts/launchpad.json";
import stakingPoolFactory from "../contracts/staking.json";

import lockABI from "../config/abi/Lock.json";
import ERC20ABI from "../config/abi/IERC20.json";

import TokenFactoryManagerABI from '../config/abi/TokenFactoryManager.json';
import AirdropABI from '../config/abi/AirDrop.json'
import Web3 from 'web3';
import { Lock_Addr, Airdrop_Addr } from "./addressHelpers";

const PancakeswapPairABI = launchpadContracts.abi.pancakeswap;
const PoolFactoryABI = launchpadContracts.abi.poolFactory;
const FairPoolFactoryABI = launchpadContracts.abi.fairPoolFactory;
const PoolABI = launchpadContracts.abi.pool;
const FairPoolABI = launchpadContracts.abi.fairPool;
const StandardTokenFactoryABI = launchpadContracts.abi.standardTokenFactory;
const LiquidityGeneratorTokenFactoryABI = launchpadContracts.abi.liquidityGeneratorTokenFactory;
const BabyTokenFactoryABI = launchpadContracts.abi.babyTokenFactory;
const BuyBackBabyTokenFactoryABI = launchpadContracts.abi.buybackBabyTokenFactory;
const AntiBotStandardTokenFactoryABI = launchpadContracts.abi.antiBotStandardTokenFactory;
const AntiBotLiquidityGeneratorTokenFactoryABI = launchpadContracts.abi.antiBotLiquidityGeneratorTokenFactory;
const AntiBotBabyTokenFactoryABI = launchpadContracts.abi.antiBotBabyTokenFactory;
const AntiBotBuyBackBabyTokenFactoryABI = launchpadContracts.abi.antiBotBuybackBabyTokenFactory;
const AntiBotABI = launchpadContracts.abi.antiBot;

const RPC_URL = 'https://data-seed-prebsc-1-s1.binance.org:8545';
const httpProvider = new Web3.providers.HttpProvider(RPC_URL, {
  timeout: 10000
});

export const getBalance = async (_account) => {
  const web3 = new Web3(window.ethereum);
  const _balance = await web3.eth.getBalance(_account);
  console.log(_balance);
  return _balance;
}

/**
 * Provides a web3 instance using wallet provider
 */

const getContract = async (address, abi, signer) => {
  if (signer)
    window.web3 = new Web3(window.ethereum);
  else
    window.web3 = new Web3('https://rpc.icecreamswap.com');
  const contract = await new window.web3.eth.Contract(abi, address);
  //TODO Check chain id on functions calling getContract
  return { contract };
};

export const getLockContract = async (signer = false) => {
  const { contract } = await getContract(
    Lock_Addr,
    lockABI,
    signer
  );
  return contract;
};
export const getPancakeswapPairContract = async (address, signer = false) => {
  const { contract } = await getContract(address, PancakeswapPairABI, signer);
  return contract;
};
export const getERC20Contract = async (address, signer = false) => {
  const { contract } = await getContract(address, ERC20ABI, signer);
  return contract;
};

export const getPoolFactoryContract = async (address, signer = false) => {
  const { contract } = await getContract(address, PoolFactoryABI, signer);
  return contract;
}

export const getFairPoolFactoryContract = async (address, signer = false) => {
  const { contract } = await getContract(address, FairPoolFactoryABI, signer);
  return contract;
}

export const getPoolContract = async (address, signer = false) => {
  const { contract } = await getContract(address, PoolABI, signer);
  return contract;
}

export const getFairPoolContract = async (address, signer = false) => {
  const { contract } = await getContract(address, FairPoolABI, signer);
  return contract;
}

export const getAntiBotStandardTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, AntiBotStandardTokenFactoryABI, signer);
  return contract;
}

export const getStandardTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, StandardTokenFactoryABI, signer);
  return contract;
}

export const getAntibotBabyTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, AntiBotBabyTokenFactoryABI, signer);
  return contract;
}

export const getBabyTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, BabyTokenFactoryABI, signer);
  return contract;
}

export const getAntiBotLiquidityGeneratorTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, AntiBotLiquidityGeneratorTokenFactoryABI, signer);
  return contract;
}

export const getLiquidityGeneratorTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, LiquidityGeneratorTokenFactoryABI, signer);
  return contract;
}

export const getAntiBotBuyBackBabyTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, AntiBotBuyBackBabyTokenFactoryABI, signer);
  return contract;
}

export const getBuyBackBabyTokenFactory = async (address, signer = false) => {
  const { contract } = await getContract(address, BuyBackBabyTokenFactoryABI, signer);
  return contract;
}

export const getTokenFactoryManager = async (address, signer = false) => {
  const { contract } = await getContract(address, TokenFactoryManagerABI, signer);
  return contract;
}

export const getAntiBotContract = async (address, signer = false) => {
  const { contract } = await getContract(address, AntiBotABI, signer);
  return contract;
}

export const getAirdropContract = async (signer = false) => {
  const { contract } = await getContract(Airdrop_Addr, AirdropABI, signer);
  return contract;
}

export const getStakingPoolFactoryContract = async(signer = false) => {
  const { contract } = await getContract(stakingPoolFactory.address, stakingPoolFactory.abi, signer);
  return contract;
}

export const isAddress = (address) => {
  const web3 = new Web3(window.ethereum);
  return web3.utils.isAddress(address);
}