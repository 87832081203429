import React, { useState, useEffect } from 'react';
import AllLaunchpads from './AllLaunchpads';
import MyContributions from './MyContributions';
// import dashboardIcon from "./assets/img/sidebar/dashboard-icon.svg"
import { AiOutlineMenu } from "react-icons/ai";
import {IoIceCream, IoRocketSharp} from "react-icons/io5";
import {Button, Col, Dropdown, Menu, Row, Space, Tooltip} from "antd";
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { BsPlusCircleFill } from "react-icons/bs";
import { IoRocketOutline } from "react-icons/io5";
import { RiCoinLine } from "react-icons/ri";
import { Link } from "react-router-dom"


import { buttonUnstyledClasses, TabsListUnstyled, TabsUnstyled, TabUnstyled, tabUnstyledClasses } from "@mui/base";
import {Box, styled} from "@mui/material";

export default function LaunchpadList({ account, setPage, networkID }) {

  const [tab, setTab] = useState(0);

  useEffect(() => setPage(23), [])

  const Tab = styled(props => <TabUnstyled {...props} />)`
  font-family:  Inter, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  fontSize : '16px';
  background-color: transparent;
  width: 80px;
  padding-top : 0px;
  border: none;
  border-radius: 12px;
  textDecoration: 'none';
  display: flex;
  justify-content: center;
  height : 20px;
  color : #9aa2be;
  &:hover {
    color: #fff;
  };

  &.${tabUnstyledClasses.selected} {
    background-color: #2f2c3a;
    color: #fff;
    height : 30px;
    padding-top : 5px;
  }

`;

    const TabsList =styled(props => <TabsListUnstyled {...props} />)`  
      a {
          text-decoration: none;
          color : #9aa2be;
          &:hover {
            color: #fff;
          }
      }
  min-width: 100px;
      //width: 450px;
  height : 38px;
  background-color: #110e19;
  border-radius: 13px;
  margin-bottom: 16px;
  padding-left : 5px;
  padding-right : 5px;
  margin-top : 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
    const isMobile = window.screen.width < 600
  return (
    <div className='launchpad-list'>

        <Row justify="end" style={{marginBottom : '30px'}}>
            <Col xl={4}></Col>
            <Col xl={8}>
                { !isMobile &&
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        {/*<Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>*/}

                        <TabsUnstyled defaultValue={4}>
                            <TabsList>
                                <Tab>
                                    <a href="//app.icecreamswap.com/#/swap"  rel="noreferrer">
                                        Swap
                                    </a>
                                </Tab>
                                <Tab>
                                    <a href="//app.icecreamswap.com/#/pool"  rel="noreferrer">
                                        Pool
                                    </a>
                                </Tab>
                                <Tab>
                                    <a href="//info.icecreamswap.com/"  rel="noreferrer">
                                        Info
                                    </a>
                                </Tab>
                                <Tab >
                                    <a href="//bridge.icecreamswap.com/"  rel="noreferrer">
                                        Bridge
                                    </a>
                                </Tab>
                                <Tab>
                                        Launchpad
                                </Tab>
                            </TabsList>
                        </TabsUnstyled>
                    </Box> }
            </Col>
            <Col style={{ display: 'block'}} xl={8} sm={24}>
                <Tooltip placement="bottom" title="Create Token">
                <Button style={{ float: 'right', width : '130px' }} type="primary">
                    <Link to="/create_token">
                        <BsPlusCircleFill style={{marginRight: '7px',fontSize: '18px' }}/> Token <RiCoinLine style={{ fontSize: '20px'}}/>
                    </Link>
                </Button>
                </Tooltip>
                <Tooltip placement="bottom" title="Create Launchpad">
                <Button style={{ float: 'right', width : '130px', marginRight:'10px' }} type="primary">
                    <Link to="/create_launchpad">
                        <BsPlusCircleFill style={{marginRight: '7px',fontSize: '18px' }}/> Launchpad <IoRocketOutline style={{ fontSize: '20px'}}/>
                    </Link>
                </Button>
                </Tooltip>
            </Col>
        </Row>

      <div className="tab-switcher">
        <div className={tab === 0 ? "tab-active" : "tab"} onClick={() => setTab(0)}>All Launchpads</div>
        <div className={tab === 1 ? "tab-active" : "tab"} onClick={() => setTab(1)}>My Contributions</div>
      </div>
      {tab === 0 && <AllLaunchpads account={account} networkID={networkID} />}
      {tab === 1 && <MyContributions account={account} networkID={networkID} />}
    </div>
  )
}
