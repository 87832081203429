import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { useMutation } from "@apollo/client";

import { CREATE_LAUNCHPAD, CREATE_APPROVAL } from "../../../graphql/Launchpad/Mutation";
import Steps from "../../../components/Steps";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { getPoolFactoryContract } from '../../../utils/contractHelpers';
import { IcecreamRouter_Addr, PoolFactory_Addr, Pool_Addr } from '../../../utils/addressHelpers';
import LaunchInfoItem from '../../../components/LaunchInfoItem';
import {Button, Col, Row} from "antd";
import { ImList2 } from "react-icons/im";
import { MdKeyboardBackspace } from "react-icons/md";
import {Link} from "react-router-dom";

export default function CreateLaunchpad({ account, setPage, networkID }) {
  
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  // Step 1
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState("");
  const [tokenTotalSupply, setTokenTotalSupply] = useState("");
  const [currency, setCurrency] = useState("Brise");
  const [feeOptions, setFeeOptions] = useState(1);

  //Step2
  const [presaleRate, setPresaleRate] = useState("");
  const [whitelist, setWhitelist] = useState(0);
  const [softcap, setSoftcap] = useState("");
  const [hardcap, setHardcap] = useState("");
  const [minimumBuy, setMininumBuy] = useState("");
  const [maximumBuy, setMaxinumBuy] = useState("");
  const [refundType, setRefundType] = useState(0);
  const [router, setRouter] = useState("IcecreamSwap");
  const [liquidity, setLiquidity] = useState("");
  const [listingRate, setListingRate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [startTimeString, setStartTimeString] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endTimeString, setEndTimeString] = useState("");
  const [liquidityLockup, setLiquidityLockup] = useState("");
  const [isVesting, setIsVesting] = useState(false);
  const [isTeamVesting, setIsTeamVesting] = useState(false);
  const [firstRelease, setFirstRelease] = useState("");
  const [vestingPeriod, setVestingPeriod] = useState("");
  const [presaleTokenRelease, setPresaleTokenRelease] = useState("");
  const [totalVestingTokens, setTotalVestingTokens] = useState("");
  const [firstReleaseMinutes, setFirstReleaseMinutes] = useState("");
  const [firstReleasePercent, setFirstReleasePercent] = useState("");
  const [teamVestingPeriod, setTeamVestingPeriod] = useState("");
  const [teamTokenRelease, setTeamTokenRelease] = useState("");
  const [needAmount, setNeedAmount] = useState("");  //...

  //Step 3
  const [logoImage, setLogoImage] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [github, setGithub] = useState("");
  const [telegram, setTelegram] = useState("");
  const [instagram, setInstagram] = useState("");
  const [discord, setDiscord] = useState("");
  const [reddit, setReddit] = useState("");
  const [youtube, setYoutube] = useState("");
  const [description, setDescription] = useState("");

  // Step 4
  const [pending, setPending] = useState(false);

  const [createLaunchpad, { error }] = useMutation(CREATE_LAUNCHPAD);
  const [createApproval] = useMutation(CREATE_APPROVAL);

  const onSubmit = async () => {
    setPending(true);
    let addrs = [account, tokenAddress, IcecreamRouter_Addr, account];
    const poolFactory = await getPoolFactoryContract(PoolFactory_Addr, true);
    const rateSetting = ['0x' + (presaleRate * Math.pow(10, tokenDecimals)).toString(16), '0x' + (listingRate * Math.pow(10, tokenDecimals)).toString(16)];
    const contributionSettings = ['0x' + (minimumBuy * Math.pow(10, 18)).toString(16), '0x' + (maximumBuy * Math.pow(10, 18)).toString(16)];
    const capSettings = ['0x' + (softcap * Math.pow(10, 18)).toString(16), '0x' + (hardcap * Math.pow(10, 18)).toString(16)];
    const timeSettings = [Math.ceil(startTime.format("x") / 1000), Math.ceil(endTime.format("x") / 1000), liquidityLockup * 60];
    const vestingSettings = !isVesting ? [0, 0, 0] : [firstRelease, vestingPeriod * 60, presaleTokenRelease];
    const teamVestingSettings = !isTeamVesting ? [0, 0, 0, 0, 0] : ['0x' + (totalVestingTokens * Math.pow(10, tokenDecimals)).toString(16), firstReleaseMinutes * 60, firstReleasePercent, teamVestingPeriod * 60, teamTokenRelease];
    const urls = logoImage + ' ' + website;
    console.log(rateSetting, contributionSettings, capSettings, timeSettings, vestingSettings, teamVestingSettings, urls)
    const fee = await poolFactory.methods.createFee().call();
    try {
      const pool = await poolFactory.methods.createPool(Pool_Addr, addrs, rateSetting, contributionSettings, capSettings,
        timeSettings, vestingSettings, teamVestingSettings, urls, liquidity, [refundType, whitelist], description).send({ from: account, value: fee });
      const poolAddress = pool.events.CreatePool.returnValues.pool;
      // const contract = await getERC20Contract(wallet, token);
      // const amount = '0x' + (100 * Math.pow(10, 18)).toString(16);
      // await contract.methods.transferFrom(wallet.account, '0xCaD6291eb218371b610f1d17e56F259ad6734229', amount).send({ from: wallet.account })\

      try {
        console.log(poolAddress);
        createLaunchpad({
          variables: {
            name: `${tokenName} Presale`,
            owner: account,
            launchpadAddress: poolAddress,
            tokenAddress: tokenAddress,
            tokenName: tokenName,
            tokenSymbol: tokenSymbol,
            tokenDecimals: parseFloat(tokenDecimals),
            totalSupply: parseInt(tokenTotalSupply),
            currency: currency,
            isWhitelisted: whitelist? true: false,
            isFair: false,
            sellingAmount: 0,
            presaleRate: parseFloat(presaleRate),
            listingRate: parseFloat(listingRate),
            softcap: parseFloat(softcap),
            hardcap: parseFloat(hardcap),
            refundType: refundType? "Burn": "Refund",
            startTime: startTimeString,
            endTime: endTimeString,
            router: router,
            liquidityPercent: parseFloat(liquidity),
            lockupTime: parseInt(liquidityLockup),
            maxBuy: parseFloat(maximumBuy),
            minBuy: parseFloat(minimumBuy),
            isVesting: isVesting,
            firstRelease: firstRelease === ""? 0: parseFloat(firstRelease),
            vestingPeriod: vestingPeriod === ""? 0: parseInt(vestingPeriod),
            presaleTokenRelease: presaleTokenRelease === ""? 0: parseFloat(presaleTokenRelease),
            isTeamVesting: isTeamVesting,
            totalVestingTokens: totalVestingTokens === ""? 0: parseFloat(totalVestingTokens),
            firstReleaseMinutes: firstReleaseMinutes === ""? 0: parseInt(firstReleaseMinutes),
            firstReleasePercent: firstReleasePercent === ""? 0: parseFloat(firstReleasePercent),
            teamVestingPeriod: teamVestingPeriod === ""? 0: parseInt(teamVestingPeriod),
            teamTokenRelease: teamTokenRelease === ""? 0: parseFloat(teamTokenRelease),
            needAmount: parseFloat(needAmount),
            logoImg: logoImage,
            website: website,
            facebook: facebook,
            twitter: twitter,
            github: github,
            telegram: telegram,
            instagram: instagram,
            discord: discord,
            reddit: reddit,
            youtube: youtube,
            description: description,
            soldAmount: 0,
            chain: networkID
          }
        })
        createApproval({
          variables: {
            launchpadAddress: poolAddress,
            chain: networkID
          }
        })
        setTimeout(() => {
          navigate('/pool/' + poolAddress);
        }, 2000)
      } catch (error) {
        console.log(error);
      }
      setPending(false);
    }
    catch (error) {
      console.log(error);
      setPending(false);
    }
    
  }

  useEffect(() => setPage(20), [])

  return (
    <div className="create-launchpad">
      <Row style={{marginBottom : '30px'}}>

        <Col xl={12}>
          <Link to="/">
              <MdKeyboardBackspace  style={{ float: 'left', fontSize: '30px' }}/>
            </Link>
        </Col>

      </Row>

      <Steps step={step} />
      {
        step === 0 &&
        <Step1
          account={account} setStep={setStep}
          tokenAddress={tokenAddress} setTokenAddress={setTokenAddress}
          tokenName={tokenName} setTokenName={setTokenName}
          tokenSymbol={tokenSymbol} setTokenSymbol={setTokenSymbol}
          tokenDecimals={tokenDecimals} setTokenDecimals={setTokenDecimals}
          tokenTotalSupply={tokenTotalSupply} setTokenTotalSupply={setTokenTotalSupply}
          currency={currency} setCurrency={setCurrency}
          feeOptions={feeOptions} setFeeOptions={setFeeOptions}
        />
      }

      {
        step === 1 &&
        <Step2
          account={account} setStep={setStep}
          currency={currency} tokenSymbol={tokenSymbol}
          tokenDecimals={tokenDecimals}
          presaleRate={presaleRate} setPresaleRate={setPresaleRate}
          whitelist={whitelist} setWhitelist={setWhitelist}
          softcap={softcap} setSoftcap={setSoftcap}
          hardcap={hardcap} setHardcap={setHardcap}
          minimumBuy={minimumBuy} setMininumBuy={setMininumBuy}
          maximumBuy={maximumBuy} setMaxinumBuy={setMaxinumBuy}
          refundType={refundType} setRefundType={setRefundType}
          router={router} setRouter={setRouter}
          liquidity={liquidity} setLiquidity={setLiquidity}
          listingRate={listingRate} setListingRate={setListingRate}
          startTime={startTime} setStartTime={setStartTime}
          startTimeString={startTimeString} setStartTimeString={setStartTimeString}
          endTime={endTime} setEndTime={setEndTime}
          endTimeString={endTimeString} setEndTimeString={setEndTimeString}
          liquidityLockup={liquidityLockup} setLiquidityLockup={setLiquidityLockup}
          isVesting={isVesting} setIsVesting={setIsVesting}
          isTeamVesting={isTeamVesting} setIsTeamVesting={setIsTeamVesting}
          firstRelease={firstRelease} setFirstRelease={setFirstRelease}
          vestingPeriod={vestingPeriod} setVestingPeriod={setVestingPeriod}
          presaleTokenRelease={presaleTokenRelease} setPresaleTokenRelease={setPresaleTokenRelease}
          totalVestingTokens={totalVestingTokens} setTotalVestingTokens={setTotalVestingTokens}
          firstReleaseMinutes={firstReleaseMinutes} setFirstReleaseMinutes={setFirstReleaseMinutes}
          firstReleasePercent={firstReleasePercent} setFirstReleasePercent={setFirstReleasePercent}
          teamVestingPeriod={teamVestingPeriod} setTeamVestingPeriod={setTeamVestingPeriod}
          teamTokenRelease={teamTokenRelease} setTeamTokenRelease={setTeamTokenRelease}
          needAmount={needAmount} setNeedAmount={setNeedAmount}
        />
      }

      {
        step === 2 &&
        <Step3
          account={account} setStep={setStep}
          logoImage={logoImage} setLogoImage={setLogoImage}
          website={website} setWebsite={setWebsite}
          facebook={facebook} setFacebook={setFacebook}
          twitter={twitter} setTwitter={setTwitter}
          github={github} setGithub={setGithub}
          telegram={telegram} setTelegram={setTelegram}
          instagram={instagram} setInstagram={setInstagram}
          discord={discord} setDiscord={setDiscord}
          reddit={reddit} setReddit={setReddit}
          youtube={youtube} setYoutube={setYoutube}
          description={description} setDescription={setDescription}
        />
      }

      {
        step === 3 &&
        <div className="step-4">
          <LaunchInfoItem title="Total token" info={`${needAmount} ${tokenSymbol}`} />
          {/* <LaunchInfoItem title="Factory Address" info={"0xd7c97627807Ee783a160Ae95681B53b0D7D2261d"} /> */}
          <LaunchInfoItem title="Token name" info={tokenName} />
          <LaunchInfoItem title="Token symbol" info={tokenSymbol} />
          <LaunchInfoItem title="Token decimals" info={tokenDecimals} />
          <LaunchInfoItem title="Presale rate" info={`${presaleRate} ${tokenSymbol}`} />
          <LaunchInfoItem title="Listing rate" info={`${listingRate} ${tokenSymbol}`} />
          <LaunchInfoItem title="Sale method" info={"Public"} />
          <LaunchInfoItem title="Softcap" info={`${softcap} ${currency}`} />
          <LaunchInfoItem title="Hardcap" info={`${hardcap} ${currency}`} />
          <LaunchInfoItem title="Unsold tokens" info={refundType? "Burn": "Refund"} />
          <LaunchInfoItem title="Minimum buy" info={`${minimumBuy} ${currency}`} />
          <LaunchInfoItem title="Liquidity" info={`${maximumBuy} ${currency}`} />
          <LaunchInfoItem title="Start time" info={startTimeString} />
          <LaunchInfoItem title="End time" info={endTimeString} />
          <LaunchInfoItem title="Liquidity lockup time" info={`${liquidityLockup} minutes`} />
          <LaunchInfoItem title="Website" info={website} />
          {facebook && <LaunchInfoItem title="Facebook" info={facebook} />}
          {twitter && <LaunchInfoItem title="Twitter" info={twitter} />}
          {telegram && <LaunchInfoItem title="Telegram" info={telegram} />}
          {github && <LaunchInfoItem title="Github" info={github} />}
          {instagram && <LaunchInfoItem title="Instagram" info={instagram} />}
          {discord && <LaunchInfoItem title="Discord" info={discord} />}
          {reddit && <LaunchInfoItem title="Reddit" info={reddit} />}
          {description && <LaunchInfoItem title="Description" info={description} />}
          {youtube && <LaunchInfoItem title="Youtube Video" info={youtube} />}
          <div className="text-input-item">
            <div className="tips-text">
              For tokens with burns, rebase or other special transfers please ensure that you have a way to whitelist multiple addresses or turn off the special transfer events (By setting fees to 0 for example for the duration of the presale)
            </div>
          </div>
          <div className="btn-group">
            <button className="default-btn" onClick={() => setStep(prev => prev - 1)}>Back</button>
            <button className="default-btn" disabled={pending} onClick={onSubmit}>Submit</button>
          </div>
        </div>
      }

    </div>
  )
}
