import React from 'react';

export default function Progress({ progress }) {

  return (
    <div className="progress-bar">
      <div className="progress" style={{width: `${progress}%`}}></div>
    </div>
  )
}
