import React, { useState, useEffect } from 'react';
import { useQuery } from "@apollo/client";
import { GET_ALL_LIQUIDITY_GENERATOR_TOKENS } from "../../../graphql/Launchpad/Queries";
import { toSpaceNumber } from "../../../utils/calculate";

export default function LiquidityGeneratorTokenList({ networkID }) {

    const [tokensData, setTokensData] = useState(null);

    const { data, loading, error } = useQuery(GET_ALL_LIQUIDITY_GENERATOR_TOKENS, {
        variables: {
            chain: networkID
        }
    });
    const liquidityGeneratorTokens = data;
    console.log(data, loading, error);

    const listTokens = () => {
        if (loading || error) return;
        setTokensData(liquidityGeneratorTokens.getAllLiquidityGeneratorTokens);
    }

    useEffect(() => {
        listTokens();
    }, [loading, error])

    if (loading) return (
        <div className="token-list">Loading...</div>
    )

    if (error) return (
        <div className="token-list">Fetch data error!</div>
    )

    return (
        <div className="token-list">
            <div className="column">
                <div>No</div>
                <div>Name</div>
                <div>Symbol</div>
                <div>Total Supply</div>
                <div>TokenAddress</div>
            </div>
            {tokensData?.length === 0 && <div>No tokens</div>}
            {tokensData?.map((token, index) =>
                <div className="column">
                    <div>{index + 1}</div>
                    <div>{token.name}</div>
                    <div>{token.symbol}</div>
                    <div>{toSpaceNumber(token.totalSupply)}</div>
                    <div>{token.tokenAddress}</div>
                </div>
            )}
        </div>
    )
}
