import React from 'react';

export default function (props) {
  return (
    <div className={props.size === "small"? "small-icon-button button":"icon-button button" }>
      {props.icon === undefined? null: <img src={props.icon} />}
        <div>{props.title}</div>
    </div>
  )
}
