import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import moment, { isBefore, isAfter, isMoment } from 'moment';
import { DatePicker } from "antd";
import fleekStorage from '@fleekhq/fleek-storage-js';

import TextInput from '../../../components/TextInput';
import { isNumber } from '../../../utils/calculate';

import "antd/dist/antd.css";
import calendar from "../../../assets/img/launchpad/calendar.svg";

export default function Step2({
  account, setStep,
  logoImage, setLogoImage,
  website, setWebsite,
  facebook, setFacebook,
  twitter, setTwitter,
  github, setGithub,
  telegram, setTelegram,
  instagram, setInstagram,
  discord, setDiscord,
  reddit, setReddit,
  youtube, setYoutube,
  description, setDescription
}) {

  const [createFlag, setCreateFlag] = useState(false);
  const [pending, setPending] = useState(false);
  const [checkFlags, setCheckFlags] = useState([]);

  const [imgBuffer, setImgBuffer] = useState(null);
  const [imgData, setImgData] = useState(null);

  const setImg = async (e) => {
    // console.log(e);
    const data = e.target.files[0];
    console.log(data);
    setImgData(data);
    // setImg(URL.createObjectURL(data));
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(data);
    reader.onloadend = () => {
      setImgBuffer(Buffer(reader.result));
    };
  };

  useEffect(() => {
    uploadToIpfs(imgBuffer);
  }, [imgBuffer]);

  const uploadToIpfs = async (_imgBuffer) => {
    try {
      console.log(_imgBuffer);
      const uploadedFile = await fleekStorage.upload({
        apiKey: "AUziS4MzbUmatlBv6v/xaA==",
        apiSecret: "QFKD836p6DcfY4oqFpDcRH3cODUBkP7d9YPjQmxawwo=",
        key: imgData.name,
        data: _imgBuffer,
        bucket: "21b78ae5-2a0f-442b-bf52-fa2984aebf48-bucket/Metaapp",
        httpUploadProgressCallback: (e) => {
          console.log(e);
        }
      })
      console.log(uploadedFile);
      const url = `https://ipfs.fleek.co/ipfs/${uploadedFile.hash}`;
      console.log("image url: ", url);
      setLogoImage(url);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    let temp = [];
    temp[0] = !logoImage ? 1 : 0;
    temp[1] = website.length === 0 ? 1 : 0;
    setCheckFlags(temp);
    setCreateFlag(!temp[0]*!temp[1]);
    console.log(description);
  }, [logoImage, website])

  return (
    <div className="step-3">
      <div className="warning-text">(*) is required field.</div>
      <div className="text-input-group">
        <div className="text-input-item">
          <label className="text-white"><div>Logo Image<span>*</span></div></label>
          <input type="file" id="logo-img" accept="image/png, image/gif, image/jpeg" onChange={setImg} style={{ display: "none" }}></input>
          <label htmlFor="logo-img" className="text-input">Click to upload</label>
          {checkFlags[0] ? <div className="warning-text">This field is required</div> : null}
          {logoImage && <div className="tips-text">Uploaded to IPFS</div>}
        </div>
        <TextInput
          type="url"
          title="Website"
          isRequired={true}
          value={website}
          setValue={setWebsite}
          placeholder="Ex: https://..."
          warning={checkFlags[1]}
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Facebook"
          isRequired={false}
          value={facebook}
          setValue={setFacebook}
          placeholder="Ex: https://facebook.com/..."
        />
        <TextInput
          title="Twitter"
          isRequired={false}
          value={twitter}
          setValue={setTwitter}
          placeholder="Ex: https://twitter.com/..."
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Github"
          isRequired={false}
          value={github}
          setValue={setGithub}
          placeholder="Ex: https://github.com/..."
        />
        <TextInput
          title="Telegram"
          isRequired={false}
          value={telegram}
          setValue={setTelegram}
          placeholder="Ex: https://t.me/..."
        />
      </div>
      <div className="text-input-group">
        <TextInput
          title="Instagram"
          isRequired={false}
          value={instagram}
          setValue={setInstagram}
          placeholder="Ex: https://instagram.com/..."
        />
        <TextInput
          title="Discord"
          isRequired={false}
          value={discord}
          setValue={setDiscord}
          placeholder="Ex: https://t.me/..."
        />
      </div>
      <TextInput
        title="Reddit"
        isRequired={false}
        value={reddit}
        setValue={setReddit}
        placeholder="Ex: https://reddit.com/..."
      />
      <TextInput
        title="Youtube Video"
        isRequired={false}
        value={youtube}
        setValue={setYoutube}
        placeholder="Ex: https://www.youtube.com/watch?v=xxxxxxxxxx"
      />
      <div className="text-input-item">
        <label htmlFor="" className="text-white"><div>Description</div></label>
        <textarea
          className="text-input-area"
          rows={4}
          placeholder="Ex: This is the best project..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="btn-group">
        <button className="default-btn" onClick={() => setStep(prev => prev - 1)}>Back</button>
        <button className="default-btn" disabled={!createFlag || pending} onClick={() => setStep(prev => prev + 1)}>Next</button>
      </div>
    </div>
  )
}
