import { gql } from "@apollo/client";

export const GET_ALL_STANDARD_TOKENS = gql`
    query getAllStandardTokens($chain: Int!) {
        getAllStandardTokens(chain: $chain) {
            name
            symbol
            decimals
            totalSupply
            isAntiBot
            tokenAddress
            chain
        }
    }
`;

export const GET_ALL_LIQUIDITY_GENERATOR_TOKENS = gql`
    query getAllLiquidityGeneratorTokens($chain: Int!) {
        getAllLiquidityGeneratorTokens(chain: $chain) {
            name
            symbol
            totalSupply
            yieldFee
            liquidityFee
            marketingAddress
            marketingPercent
            isAntiBot
            tokenAddress
            creatorAddress
            chain
        }
    }
`;

export const GET_ALL_BABY_TOKENS = gql`
    query getAllBabyTokens($chain: Int!) {
        getAllBabyTokens(chain: $chain) {
            name
            symbol
            totalSupply
            rewardToken
            minimumTokenBalance
            tokenRewardFee
            autoAddLiquidity
            marketingFee
            marketingWallet
            isAntiBot
            tokenAddress
            creatorAddress
            chain
        }
    }
`;

export const GET_ALL_BUYBACK_BABY_TOKENS = gql`
    query getAllBuybackBabyTokens($chain: Int!) {
        getAllBuybackBabyTokens(chain: $chain) {
            name
            symbol
            totalSupply
            rewardToken
            buybackFee
            reflectionFee
            liquidityFee
            marketingFee
            isAntiBot
            tokenAddress
            creatorAddress
            chain
        }
    }
`;

export const GET_ALL_LAUNCHPADS = gql`
    query getAllLaunchpads($chain: Int!) {
        getAllLaunchpads(chain: $chain) {
            name
            owner
            launchpadAddress
            tokenAddress
            tokenName
            tokenSymbol
            tokenDecimals
            totalSupply
            currency
            isWhitelisted
            isFair
            sellingAmount
            needAmount
            presaleRate
            listingRate
            softcap
            hardcap
            refundType
            startTime
            endTime
            router
            liquidityPercent
            lockupTime
            maxBuy
            minBuy
            isVesting
            firstRelease
            vestingPeriod
            presaleTokenRelease
            isTeamVesting
            totalVestingTokens
            firstReleaseMinutes
            firstReleasePercent
            teamVestingPeriod
            teamTokenRelease
            logoImg
            website
            facebook
            twitter
            github
            telegram
            instagram
            discord
            reddit
            youtube
            description
            soldAmount
            isCanceled
            isEnded
            chain
        }
    }
`

export const GET_LAUNCHPAD_BY_OWNER = gql`
    query getLaunchpadByOwner($owner: String!, $chain: Int!) {
        getLaunchpadByOwner(owner: $owner, chain: $chain) {
            name
            owner
            launchpadAddress
            tokenAddress
            tokenName
            tokenSymbol
            tokenDecimals
            totalSupply
            currency
            isWhitelisted
            isFair
            sellingAmount
            needAmount
            presaleRate
            listingRate
            softcap
            hardcap
            refundType
            startTime
            endTime
            router
            liquidityPercent
            lockupTime
            maxBuy
            minBuy
            isVesting
            firstRelease
            vestingPeriod
            presaleTokenRelease
            isTeamVesting
            totalVestingTokens
            firstReleaseMinutes
            firstReleasePercent
            teamVestingPeriod
            teamTokenRelease
            logoImg
            website
            facebook
            twitter
            github
            telegram
            instagram
            discord
            reddit
            youtube
            description
            soldAmount
            isCanceled
            isEnded
            chain
        }
    }
`

export const GET_LAUNCHPAD_BY_ADDRESS = gql`
    query getLaunchpadByAddress($launchpadAddress: String!, $chain: Int!) {
        getLaunchpadByAddress(launchpadAddress: $launchpadAddress, chain: $chain) {
            name
            owner
            launchpadAddress
            tokenAddress
            tokenName
            tokenSymbol
            tokenDecimals
            totalSupply
            currency
            isWhitelisted
            isFair
            sellingAmount
            needAmount
            presaleRate
            listingRate
            softcap
            hardcap
            refundType
            startTime
            endTime
            router
            liquidityPercent
            lockupTime
            maxBuy
            minBuy
            isVesting
            firstRelease
            vestingPeriod
            presaleTokenRelease
            isTeamVesting
            totalVestingTokens
            firstReleaseMinutes
            firstReleasePercent
            teamVestingPeriod
            teamTokenRelease
            logoImg
            website
            facebook
            twitter
            github
            telegram
            instagram
            discord
            reddit
            youtube
            description
            soldAmount
            isCanceled
            isEnded
            chain
        }
    }
`

export const GET_WHITELIST = gql`
    query getWhitelist($launchpadAddress: String!, $chain: Int!) {
        getWhitelist(launchpadAddress: $launchpadAddress, chain: $chain) {
            launchpadAddress
            listedAddress
            chain
        }
    }
`;

export const GET_APPROVAL = gql`
    query getApproval($chain: Int!) {
        getApproval(chain: $chain) {
            launchpadAddress
            approved
            audited
            kyced
            doxxed
        }
    }
`;